import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Formation} from "../../shared/models/formation";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {Subscription} from "rxjs/Rx";
import {PreInscriptionFormation} from "../../shared/models/pre_inscription_formation";
import {Utils} from "../../shared/utils";
import {Teacher} from "../../shared/models/Teacher";
import * as moment from "moment";
import {Student} from "../../shared/models/student";
import {environment} from "../../../environments/environment";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-list-demand-post-training',
  templateUrl: './list-demand-post-training.component.html',
  styleUrls: ['./list-demand-post-training.component.css']
})
export class ListDemandPostTrainingComponent implements OnInit {

  formationId: number;
  formation: Formation = new Formation();
  preInscriptionsFormation: PreInscriptionFormation[] = [];

  sessionId: number;

  busy: Subscription;

  baseUrl: string = environment.baseUrl + "/";

  selectedTeacher: Teacher;
  selectedStudent: Student;

  constructor(private route: ActivatedRoute, private postGraduateTrainingService: PostGraduateTrainingService) {

    this.formationId = parseInt(this.route.snapshot.paramMap.get('formationId'), 0);
    this.sessionId = parseInt(this.route.snapshot.paramMap.get('sessionId'), 0);
  }

  ngOnInit() {

    this.loadFormation();
    this.loadCandidatsByFormation();

  }

  loadCandidatsByFormation() {
    // if(this.formation)
    this.busy = this.postGraduateTrainingService.getInscriptionsByFormation(this.sessionId, this.formationId)
      .subscribe(
        (data: PreInscriptionFormation[]) => {
          console.log(data);
          this.preInscriptionsFormation = data;
          Utils.initializeDataTables(20, 6, "Liste des candidats");

        },
        (error) => {
          if (error.status === 401) {
            swal("Erreur", "Vous n'avez pas droit d'accéeder à cette formation", "error");
          }

        }
      )
  }

  loadFormation() {
    this.busy = this.postGraduateTrainingService.getFormationById(this.formationId)
      .subscribe(
        (data: Formation) => {
          this.formation = data;
        },
        (error) => {
          if (error.status === 401) {
            swal("Erreur", "Vous n'avez pas droit d'accéeder à cette formation", "error");
          }

        }
      );
  }

  changeStatusPreInscription(index: number, status: number) {
    this.busy = this.postGraduateTrainingService.changeDecisionCoordinateur(this.formationId, this.preInscriptionsFormation[index].id_pre_inscription_formation,
      status)
      .subscribe(
        (data) => {
          swal('Succée', 'Votre décision à été prise en compte avec succées', 'success');
          this.preInscriptionsFormation[index].status = status;
        },
        (error) => {
          swal('Erreur', 'Erreur', 'error');
        }
      )
  }

  viewDetailCandidate(index: number) {
    this.selectedStudent = null;
    this.selectedTeacher = null;

    if (this.preInscriptionsFormation[index].teacher) {
      this.selectedTeacher = this.preInscriptionsFormation[index].teacher;
      this.selectedTeacher.age = moment().diff(this.selectedTeacher.birthday, 'years');
    }

    if (this.preInscriptionsFormation[index].student) {
      this.selectedStudent = this.preInscriptionsFormation[index].student;
      this.selectedStudent.age = moment().diff(this.selectedStudent.birthday, 'years');
    }

    setTimeout(function () {
      jQuery('.modal-detail-candidate').modal();
    }, 20);
  }
}
