///<reference path="../../shared/utils.ts"/>
import {Component, OnInit} from '@angular/core';
import {Formation} from "../../shared/models/formation";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {Subscription} from "rxjs/Rx";
import {Utils} from "../../shared/utils";
import {UserService} from "../../shared/services/user.service";
import {PreInscriptionFormation} from "../../shared/models/pre_inscription_formation";
import {SessionFormation} from "../../shared/models/session_formation";
import {Inscription_Formation} from "../../shared/models/inscription_formation";
import {ItemSessionFormation} from "../../shared/models/item-session-formation";
import {environment} from "../../../environments/environment";

declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-post-graduate-training',
  templateUrl: './post-graduate-training.component.html',
  styleUrls: ['./post-graduate-training.component.css']
})
export class PostGraduateTrainingComponent implements OnInit {

  busy: Subscription;
  cecFormations: Formation[] = [];
  mrFormations: Formation[] = [];
  mpFormations: Formation[] = [];
  formations: Formation[] = [];

  selectedFormations: Formation[] = [];

  choiceFix: boolean = false;
  isSended: boolean = false;

  enableChoice: boolean = false;
  sessionFormation: SessionFormation = new SessionFormation();
  inscriptionsFormations: Inscription_Formation[] = [];
  paymentMedia: string;
  private submitted: boolean = false;
  busy2: Subscription;
  private inscriNumber: number = 0;

  constructor(private postGraduateTrainingService: PostGraduateTrainingService,
              private userService: UserService) {
  }

  ngOnInit() {

    this.busy = this.postGraduateTrainingService.getSessionFormationByYearUniversity(Utils.getCurrentUniversityYear(1))
      .subscribe(
        (data: SessionFormation) => {
          this.sessionFormation = data;
          console.log(this.sessionFormation);
          console.log(
            Utils.dateBetween(Utils.convertRealDateServer(this.sessionFormation.start_date_choice),
              Utils.convertRealDateServer(this.sessionFormation.end_date_choice)));
          this.enableChoice = Utils.dateBetween(Utils.convertRealDateServer(this.sessionFormation.start_date_choice),
            Utils.convertRealDateServer(this.sessionFormation.end_date_choice));

          this.getAllInscriptions(this.sessionFormation.id_session_formation);
          this.getAllFormation(this.sessionFormation.id_session_formation);
          this.getChoicesByCandidat(this.sessionFormation.id_session_formation);
        },
        (error) => {

        }
      );
    this.initRegistrationPaymentMedia();
  }

  getChoicesByCandidat(sessionFormationId) {
    this.busy = this.postGraduateTrainingService.getChoicesByCandidat(sessionFormationId)
      .subscribe((data: PreInscriptionFormation[]) => {
          if (data.length !== 0) {
            this.isSended = true;
            for (let i = 0; i < data.length; i++) {
              data[i].formation.formation.status = data[i].status;
              this.selectedFormations.push(data[i].formation.formation);
            }
          }
        },
        error => {

        });
  }

  private onChangeFiles(className: string, index: number) {
    const baseContext = this;
    jQuery('.' + className).change(function () {
      console.log("On Change");
    }).on('fileuploaded', function (event, data, previewId) {
      console.log(data.response);
      console.log(baseContext.selectedFormations[index]);
      if (className.indexOf('lettre-motiv') !== -1) {
        baseContext.selectedFormations[index].id_lettre_motiv = data.response.lettre.id_lettre_motiv;
      } else {
        baseContext.selectedFormations[index].id_curriculum_vitae = data.response.cv.id_curriculum_vitae;
      }
      console.log(baseContext.selectedFormations);
      console.log("test");
    }).on('filedeleted', function (event, key, jqXHR, data) {
      console.log("deleted !!!");
    });
  }

  private getAllFormation(sessionFormationId: number) {
    const baseContext = this;
    this.busy = this.postGraduateTrainingService.getAllFormationByTeacher(sessionFormationId)
      .subscribe(
        (data: ItemSessionFormation[]) => {
          this.formations = Utils.convertToFormation(data);
          this.cecFormations = this.getFormationsByType(1, this.formations);
          this.mrFormations = this.getFormationsByType(3, this.formations);
          this.mpFormations = this.getFormationsByType(2, this.formations);

          setTimeout(function () {
            baseContext.initCheckBox();
          }, 20);
        },
        (error) => {

        }
      )
  }

  initCheckBox() {
    const baseContext = this;
    for (let i = 0; i < baseContext.formations.length; i++) {
      baseContext.initItemCheckbox(baseContext.formations[i].id_formation);
    }
  }

  initItemCheckbox(formationId: number) {
    const baseContext = this;
    const checkBox = jQuery('.checkbox-inline-na-' + formationId);
    checkBox.uniform({
      radioClass: 'choice'
    });

    checkBox.on('change', function () {
      const formationId: number = parseInt(jQuery(this).val(), 0);
      const indexInSelected = baseContext.selectedFormations.map(
        function (x) {
          return x.id_formation
        }
      ).indexOf(formationId);

      if (indexInSelected !== -1) {
        baseContext.selectedFormations.splice(indexInSelected, 1);
      } else {
        if (baseContext.selectedFormations.length === 4) {
          swal('Warning', 'Vous avez dépassé le nombre limite des choix', 'warning');
          checkBox.prop('checked', false);
          jQuery.uniform.update();
          return;
        }
        const indexInData = baseContext.formations.map(
          function (x) {
            return x.id_formation
          }
        ).indexOf(formationId);
        baseContext.selectedFormations.push(baseContext.formations[indexInData]);

        if (baseContext.selectedFormations.length === 1) {
          setTimeout(function () {
            baseContext.initSortableClassementChoices(false);
          }, 20)
        } else {
          setTimeout(function () {
            baseContext.initSortableClassementChoices(true);
          }, 20)
        }
      }
      console.log(baseContext.selectedFormations);
    });
  }

  getFormationsByType(formationTypeId: number, formationData: Formation[]) {
    const formations: Formation[] = [];
    formationData.forEach(
      formation => {
        if (formation.id_type_formation === formationTypeId) {
          formations.push(formation);
        }
      }
    )
    return formations;
  }

  initItemCV(index: number) {
    const baseContext = this;
    console.log('cv-' + index);
    Utils.initializeBasicUploadFile(environment.baseUrl + "/teacher/formation/curriculum-vitae/add",
      baseContext.userService.getTokent(), 'cv-' + index);
    baseContext.onChangeFiles('cv-' + index, index);
  }

  initItemLettreMotiv(index: number) {
    const baseContext = this;
    console.log('lettre-motiv-' + index);
    Utils.initializeBasicUploadFile(environment.baseUrl + "/teacher/formation/lettre-motive/add",
      baseContext.userService.getTokent(), 'lettre-motiv-' + index);
    baseContext.onChangeFiles('lettre-motiv-' + index, index);
  }

  fixChoice() {
    const baseContext = this;
    this.choiceFix = !this.choiceFix;
    if (this.choiceFix) {
      const posIdsChoices = jQuery("#classement-choice").sortable("toArray");
      baseContext.orderSelectedFormations(posIdsChoices);
      for (let i = 0; i < this.selectedFormations.length; i++) {
        this.selectedFormations[i].id_lettre_motiv = null;
        this.selectedFormations[i].id_curriculum_vitae = null;
        setTimeout(function () {
          baseContext.initItemLettreMotiv(i);
          baseContext.initItemCV(i);
        }, 20)
      }
    } else {
      setTimeout(function () {
        baseContext.initSortableClassementChoices(false);
      }, 20)
    }
  }

  orderSelectedFormations(idsChoices: string[]) {
    const baseContext = this;
    const formations: Formation[] = [];
    for (let i = 0; i < idsChoices.length; i++) {
      const indexFormation = baseContext.selectedFormations.map(
        function (x) {
          return x.id_formation
        }
      ).indexOf(parseInt(idsChoices[i], 0));
      formations.push(baseContext.selectedFormations[indexFormation]);
    }
    baseContext.selectedFormations = [];
    baseContext.selectedFormations = formations;
  }

  submitResult() {
    console.log(this.selectedFormations);
    const baseContext = this;
    if (this.selectedFormations.length === 0) {
      swal('Warning !', "Vous n'avez choisi aucun formation", "warning");
      return;
    }
    if (!this.isValidChamps()) {
      swal('Warning !', "Verifier que vous avez postuler les lettres de motivation et les CVs pour chaque formation", 'warning');
      return;
    }

    swal({
        title: "Vous êtes sûr?",
        text: "Une fois envoyé vous n'avez pas le droit de le modifée !",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui, envoyer!",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.postGraduateTrainingService.addCandidateChoice(baseContext.selectedFormations)
            .subscribe(
              (data) => {
                console.log(data);
                swal('Succées', 'Votre Candidature à été envoyé avec succées', 'success');
                baseContext.isSended = true;
                baseContext.choiceFix = false;
              },
              (error) => {

              }
            )
        } else {
          swal({
            title: "Annulé",
            text: "Vous avez annulé cette action",
            confirmButtonColor: "#2196F3",
            type: "error"
          });
        }
      });

  }

  isValidChamps() {
    for (let i = 0; i < this.selectedFormations.length; i++) {
      if (!this.selectedFormations[i].id_formation || !this.selectedFormations[i].id_lettre_motiv || !this.selectedFormations[i].id_curriculum_vitae) {
        return false;
      }
    }
    return true;
  }

  initSortableClassementChoices(isRefresh: boolean) {
    if (isRefresh) {
      jQuery('#classement-choice').sortable("refresh");
      console.log("is refresh");
    }
    else {
      jQuery('#classement-choice').sortable();
      jQuery('#classement-choice').disableSelection();
    }
  }


  private getAllInscriptions(sessionFormationId: number) {

    this.busy = this.postGraduateTrainingService.getAllInscriptionsByTeacher(sessionFormationId)
      .subscribe(
        (data: Inscription_Formation[]) => {
          this.inscriptionsFormations = data;
          console.log(data);
        },
        (error) => {

        }
      )
  }

  setInscriNumber(i) {
    this.inscriNumber = i;
  }

  inscriptionFormation(id_inscription_formation: number) {
    console.log("method open");
    this.submitted = true;

    if (!id_inscription_formation) {
      return;
    }
    if (!this.paymentMedia) {
      swal({
        title: "Erreur!",
        text: "Vous devez uploader votre reçu de paiement",
        confirmButtonColor: "#EF5350",
        type: "error"
      });
      return;
    }

    jQuery("#modal_form_vertical").modal('toggle');
    const baseContext = this;
    this.busy2 = this.userService.inscrireTeacherFormation(
      this.inscriptionsFormations[this.inscriNumber].id_inscription_formation, this.paymentMedia)
      .subscribe(
        (data) => {
          this.inscriptionsFormations[this.inscriNumber].status = 2;
          swal({
            title: "Succès!",
            text: "L'inscription dans la formation " +
              baseContext.inscriptionsFormations[this.inscriNumber].formation.formation.label + " est éffectuée avec succées",
            confirmButtonColor: "#66BB6A",
            type: "success"
          });
        },
        (error) => {
          if (error.status === 402) {
            swal({
              title: "Erreur !",
              text: "Votre dossier n'est pas encore validé",
              confirmButtonColor: "#66BB6A",
              type: "error"
            });
          }
        }
      );

  }

  private initRegistrationPaymentMedia() {
    Utils.initializeUploadFile(environment.baseUrl + "/teacher/" + this.userService.loggedUser.id_Teacher + "/registration/payment/media/uploadFormation",
      this.userService.getTokent(), ".file-input-teacher-registration-payment-media", 1, [], [], ['png', 'pdf', 'jpeg', 'jpg']);
    const baseContext = this;
    jQuery('.file-input-teacher-registration-payment-media').change(function () {
      console.log('file input change');
    }).on('fileuploaded', function (event, data, previewId, index) {
      baseContext.paymentMedia = data.response.path;
      console.log("fileuploaded");
      swal({
        title: "Succés!",
        text: 'Vous avez ajouté une photo de votre reçu de paiement',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    }).on('filedeleted', function (event, key, jqXHR, data) {
      baseContext.paymentMedia = null;
      swal({
        title: "Succés!",
        text: 'Vous avez supprimé la photo de votre reçu de paiement',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    });
  }
}
