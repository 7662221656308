/**
 * Created by Abbes on 04/08/2017.
 */
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {SharedModule} from "../shared/shared.module";
import {PhotoComponent} from "./photo/photo.component";
import {ManageTeacherFileRouting} from "./teacher-file.routing";
import {DashboardTeacherFileComponent} from "./dashboard/dashboard-teacher-file.component";
import {BacInfoComponent} from "app/teacher-file/bac-info/bac-info.component";
import {DescriptifDocumentComponent} from "./shared/descriptif-document/descriptif-document.component";
import {DoctauratTeacherFileComponent} from "./doctaurat/doctaurat-teacher-file.component";
import {ResidanatTeacherFileComponent} from "./residanat/residanat-teacher-file.component";
import {FonctionTeacherFileComponent} from "./fonction/fonction-teacher-file.component";
import {AssisAggrProfessComponent} from "./assis-aggr-profess/assis-aggr-profess.component";
@NgModule({
  imports: [
    ManageTeacherFileRouting,
    SharedModule
  ],
  declarations: [
    DashboardTeacherFileComponent,
    PhotoComponent,
    BacInfoComponent,
    DescriptifDocumentComponent,
    FonctionTeacherFileComponent,
    DoctauratTeacherFileComponent,
    ResidanatTeacherFileComponent,
    AssisAggrProfessComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class ManageTeacherFileModule {
}
