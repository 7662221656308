import {Component, OnInit} from '@angular/core';
import {Critere} from "../../shared/models/stage/Critere";
import {StageService} from "../../shared/services/stage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs/Subscription";
import {Evaluation} from "../../shared/models/stage/Evaluation";
import {environment} from "../../../environments/environment";

declare var jQuery: any;
declare var swal: any;

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.css']
})
export class EvaluationComponent implements OnInit {

  criteres: Critere [] = [];
  evaluationId: number;
  evaluation: Evaluation = new Evaluation();
  busy: Subscription;

  baseUrl: string = environment.baseUrl;

  constructor(private stageService: StageService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.evaluationId = parseInt(this.route.snapshot.paramMap.get('evaluationId'));

    this.getEvaluation();
    // this.getAllCriteres();
  }

  getCriteresByLevel(levelId: number) {

    this.criteres.forEach(
      critere => {
        const indexLevel = critere.ponderation_levels.map(
          ponderationLevel => {
            return ponderationLevel.level_id;
          }
        ).indexOf(levelId);
        critere.ponderation = indexLevel !== -1 ? critere.ponderation_levels[indexLevel].ponderation : null;
      }
    )
  }

  getEvaluation() {
    this.busy = this.stageService.getEvaluationById(this.evaluationId)
      .subscribe(
        (data) => {
          this.evaluation = data;
          this.getAllCriteres();
        },
        (error) => {

        }
      )
  }

  getAllCriteres() {
    const baseContext = this;
    this.busy = this.stageService.getAllCriteres()
      .subscribe(
        (data) => {
          this.criteres = data;
          for (const critere of this.criteres) {
            critere.significations = [];
            for (const signfication of critere.signficiations_criteres) {
              critere.significations.push(signfication.description);
            }
          }
          this.getCriteresByLevel(this.evaluation.etudiant.niveau_id);
          this.initRadioBox();
          this.initCheckBox();
          baseContext.onChangeNote();
        },
        (error) => {

        }
      )
  }

  initRadioBox() {
    let baseContext = this;
    for (let i = 0; i < this.criteres.length; i++) {
      baseContext.criteres[i].value = 1;
      setTimeout(function () {
        baseContext.initItemRadioBox(i);
      })
    }
  }

  initCheckBox() {
    let baseContext = this;
    for (let i = 0; i < this.criteres.length; i++) {
      this.criteres[i].na = false;
      setTimeout(function () {
        baseContext.initItemCheckbox(i);
      })
    }
  }

  initItemCheckbox(index: number) {
    const baseContext = this;
    const checkBox = jQuery('.checkbox-inline-na-' + index);

    checkBox.uniform({
      radioClass: 'choice'
    });
    checkBox.on("change", function () {
      baseContext.criteres[index].na = !baseContext.criteres[index].na;
      baseContext.onChangeNote();
    });
  }

  initItemRadioBox(index: number) {
    const baseContext = this;
    const radioBox = jQuery('.radioBox-critere-' + index);

    radioBox.uniform({
      radioClass: 'choice'
    });

    radioBox.on("change", function () {
      baseContext.criteres[index].value = parseInt(jQuery(this).val());
      baseContext.onChangeNote();
    });
  }

  onChangeNote() {
    console.log(this.criteres);
    let sum = 0;
    let maxNote = 0;
    for (let i = 0; i < this.criteres.length; i++) {
      if (!this.criteres[i].na) {
        maxNote += this.criteres[i].ponderation * 4;
        sum += this.criteres[i].ponderation * this.criteres[i].value;
      }
    }

    this.evaluation.note = parseInt((sum * 20 / maxNote).toFixed(2));
  }

  submitEvaluation() {
    const baseContext = this;
    if (!this.evaluation.note) {
      swal({
        title: "Attention !",
        text: 'La note n\'est pas encore défini',
        type: "warning"
      });
    } else {
      swal({
        title: 'Vous etes sur?',
        text: "Confirmation d'evaluation de ce stage!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Oui'
      }, function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.stageService.evaluateStage(baseContext.evaluation, baseContext.criteres)
            .subscribe(
              (data) => {
                swal({
                  title: "Succées !",
                  text: 'Evaluation avec succées',
                  type: "success"
                });
                baseContext.router.navigate(['/stage/list-affectation']);
              },
              (error) => {

              }
            )

        }
      })
    }

    console.log(this.criteres);
  }

}
