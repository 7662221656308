/**
 * Created by Abbes on 04/08/2017.
 */
import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {PhotoComponent} from "./photo/photo.component";
import {TeacherRegistrationComponent} from "../registration/teacher-registration.component";
import {DashboardTeacherFileComponent} from "./dashboard/dashboard-teacher-file.component";
import {BacInfoComponent} from "./bac-info/bac-info.component";
import {DoctauratTeacherFileComponent} from "./doctaurat/doctaurat-teacher-file.component";
import {ResidanatTeacherFileComponent} from "./residanat/residanat-teacher-file.component";
import {FonctionTeacherFileComponent} from "./fonction/fonction-teacher-file.component";
import {AssisAggrProfessComponent} from "./assis-aggr-profess/assis-aggr-profess.component";

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        component: DashboardTeacherFileComponent,
        path: ''
      },
      {
        path: '',
        children: [
          {
            path: 'general/edit',
            component: TeacherRegistrationComponent
          },
          {
            path: 'bac-info',
            component: BacInfoComponent
          },
          {
            path: 'upload-photo',
            component: PhotoComponent
          },
          {
            path: 'fonction',
            component: FonctionTeacherFileComponent
          },
          {
            path: 'doctaurat',
            component: DoctauratTeacherFileComponent
          },
          {
            path: 'residanat',
            component: ResidanatTeacherFileComponent
          },
          {
            path: 'aggregation',
            component: AssisAggrProfessComponent
          },
          {
            path: 'assistanat',
            component: AssisAggrProfessComponent
          },
          {
            path: 'professorat',
            component: AssisAggrProfessComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ManageTeacherFileRouting {
}
