import {FormationNiveau} from "./formation_niveau";
import {TypeFormation} from "./type_formation";

export class Formation {
  id_formation: number;
  id_item_session_formation: number;
  label: string;
  nbr_places: number;
  capacite: number;
  description: string;
  duration: number = 1;
  isMemoire: boolean = true;
  id_type_formation: number;
  id_coordinateur: number; // TeacherId
  niveaux: FormationNiveau[] = [];

  niveauIds: number[] = [];

  type_formation: TypeFormation = new TypeFormation();

  id_lettre_motiv: number;
  id_curriculum_vitae: number;
  status: number;
}
