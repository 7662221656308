import {Component, Input, OnInit} from '@angular/core';
import {EvaluationPortfolioItem} from "../../../shared/models/stage/EvaluationPortfolioItem";

@Component({
  selector: 'app-ng-valid-portfolio',
  templateUrl: './ng-valid-portfolio.component.html',
  styleUrls: ['./ng-valid-portfolio.component.css']
})
export class NgValidPortfolioComponent implements OnInit {

  @Input()
  validation ?: EvaluationPortfolioItem;

  constructor() {
  }

  ngOnInit() {
  }

}
