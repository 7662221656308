/**
 * Created by Abbes on 16/06/2017.
 */
import {CritereLevel} from "./stage/CritereLevel";

export class Niveau {
  public id_Niveau: number;
  public niveau: string;
  public label?: string;
  public niveau_id?: number;
  public criteres_niveaux:CritereLevel[]=[];
}
