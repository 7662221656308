import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from "@angular/core";
import {SharedModule} from "../shared/shared.module";
import {InscriptionsModuleRouting} from "./inscriptions.routing";
import {MenuComponent} from "./menu/menu.component";
import {PostGraduateTrainingComponent} from './post-graduate-training/post-graduate-training.component';


/**
 * Created by AHMED on 03/08/2017.
 */
@NgModule({
  imports: [
    InscriptionsModuleRouting,
    SharedModule,
  ],
  declarations: [
    MenuComponent,
    PostGraduateTrainingComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class InscriptionsModule {
}
