import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "../shared/services/storage.service";
import {UserService} from "../shared/services/user.service";
import * as FileSaver from "file-saver";
import {Teacher} from "../shared/models/Teacher";
import {ConversationService} from "../shared/services/conversation.service";
import {PostGraduateTrainingService} from "../shared/services/post-graduate-training.service";
import {environment} from "../../environments/environment";

declare let jQuery: any;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnInit {


  components: NavigationMain[] = [];
  teacher: Teacher;
  conversationCount = 0;


  constructor(private storageService: StorageService,
              private conversationService: ConversationService,
              public router: Router, public userService: UserService,
              private route: ActivatedRoute,
              private postGraduateService: PostGraduateTrainingService) {
    console.log(userService.loggedUser);
    this.teacher = <Teacher>this.storageService.read("teacher");
    if (!this.teacher) {
      userService.loggedUser = this.teacher = new Teacher();
    }
    this.userService.getLoggedUser().subscribe(data => {
      this.teacher = this.userService.loggedUser = data.user;
      this.storageService.write("teacher", this.teacher);
      //this.attributePostFormation();
    }, error => {
      if (error.status == 401) {
        this.storageService.removeAll();
        this.router.navigateByUrl('login');
      }
    });

    /*this.conversationService.getConversationsCount().subscribe(data => {
     this.components[1].notification = data.count;
     this.components[1].childrens[0].notification = data.count;
     });*/
  }

  getTeacher(): Teacher {
    return this.userService.loggedUser ? this.userService.loggedUser : new Teacher();
  }

  ngOnInit() {


    /*
     this.partielNotificationService.getNewNotifications()
     .subscribe(
     (data) => {
     console.log(data);

     },
     (error) => {
     console.log(error);
     }
     );*/
    this.components = [
      {
        name: "Mon profile",
        url: "/teacher-file",
        icon: "icon-profile",
        visible: true,
      },
      {
        name: "Mes cours",
        visible: this.getLoggedUser().administration_review === 1,
        icon: "icon-graduation2",
        url: "/error/construction"
      },
      {
        name: "Gestion POST Formation",
        visible: this.getLoggedUser().administration_review === 1,
        icon: "icon-graduation2",
        url: "/attributions"
      },
      {
        name: "Gestion des stages",
        visible: this.getLoggedUser().administration_review === 1,
        icon: "icon-graduation2",
        childrens: [
          {
            name: "Liste affectation",
            url: "/stage/list-affectation",
          },
          {
            name: "Espace Jury des Stages",
            url: "/stage/jury"
          }]
      },
      {
        name: "Mes travaux pratiques",
        visible: this.getLoggedUser().administration_review === 1,
        url: "/error/construction",
        icon: "icon-presentation"
      },
      {
        name: "Mes travaux dirigés",
        visible: this.getLoggedUser().administration_review === 1,
        url: "/error/construction",
        icon: "icon-alignment-align"
      },
      {
        name: "Mes productions",
        visible: this.getLoggedUser().administration_review === 1,
        url: "/error/construction",
        icon: "icon-pen2"
      },
      {
        name: "Mes emprunts",
        visible: this.getLoggedUser().administration_review === 1,
        url: "/error/construction",
        icon: "icon-books"
      },
      {
        name: "Mes inscriptions",
        visible: this.getLoggedUser().administration_review === 1,
        url: "/inscriptions/post-graduate-training",
        icon: "icon-user-plus",
        notification: 1
      },
      {
        name: "Mon portfolio",
        url: "/portfolio/result-masteres",
        icon: "icon-user",
        visible: this.userService.loggedUser.administration_review === 1,
      },
      {
        name: "Mes demandes",
        visible: this.getLoggedUser().administration_review === 1,
        url: "/error/construction",
        icon: "icon-newspaper"
      },
      {
        name: "Assistance",
        icon: "icon-lifebuoy",
        visible: true,
        notification: this.conversationCount,
        childrens: [
          {
            name: "Mes réclamations",
            url: "/support/messages/all",
            notification: this.conversationCount
          },
          {
            name: "Nouvelle réclamation",
            url: "/support/reclamation"
          }]
      },


      /*{
       name: "Assistance",
       icon: "icon-pencil3",
       childrens: [
       {
       name: "Liste",
       url: "/session/list"
       },
       {
       name: "Ajout",
       url: "/session/add"
       }]
       },
       {
       name: "Gestion de la banque",
       icon: "icon-database2",
       childrens: [
       {
       name: "Liste des Stations",
       url: "/banque/list"
       },
       {
       name: "Ajout d'une stations",
       url: "/banque/add"
       },
       {
       name: "Evaluation",
       url: "/banque/evaluer"
       }
       ]
       },
       {
       name: "Gestion des Privileges",
       url: "/privilege/affect",
       icon: "icon-user-lock"
       },
       {
       name: "Organisation",
       icon: "icon-wave2",
       childrens: [
       {
       name: "Liste",
       url: "/organisation/list-personelle"
       },
       {
       name: "Suivi et controle",
       url: "/organisation/station"
       }
       ]
       },
       {
       name: "Gestion des Notes",
       url: "/notes",
       icon: "icon-file-check2"
       },*/
    ];


    this.route.queryParams.subscribe(
      params => {
        console.log(params.reload);
        if (params.reload) {
          window.location.href = "/";
        }
      }
    );

    this.changeActiveUrl(this.router.url);

    if (!this.storageService.read("teacher-token") && !this.storageService.read("enseignant-token")) {
      this.router.navigate(["/login"]);
    }

    this.getNumberOfSessions();
  }

  changeActiveUrl(url: string) {
    console.log(url);
    this.components.forEach(
      component => {
        component.active = "";
        if (url.indexOf(component.url) !== -1) {
          component.active = "active";
        }
        if (component.childrens) {
          component.childrens.forEach(
            child => {
              child.active = "";
              if (url.indexOf(child.url) !== -1) {
                child.active = "active";
              }
            }
          )
        }
      }
    )
  }

  logout() {
    this.storageService.removeAll();
    this.router.navigateByUrl("/login");
  }

  goUrl(url: string) {
    if (url) {
      this.router.navigate([url]);
    }
  }

  private getLoggedUser() {
    return this.userService.loggedUser;
  }

  generateQRcodeTeacher() {
    this.userService.generateQRcodeTeacher()
      .subscribe(
        (data) => {
          FileSaver.saveAs(data, this.teacher.first_name + " " + this.teacher.last_name + "QRcode.png");
        },
        (error) => {

        }
      )
  }

  generateDetailPDFStudent() {
    this.userService.generateDetailPDFStudent()
      .subscribe(
        (data) => {
          FileSaver.saveAs(data, this.teacher.first_name + " " + this.teacher.last_name + "_Dossier.pdf");
        },
        (error) => {

        }
      )
  }

  getTeacherImgUrl() {
    return this.getTeacher().img ? environment.baseUrl + '/' + this.getTeacher().img.path : 'assets/images/placeholder.jpg';
  }

  getNumberOfSessions() {
    /*const baseContext = this;
     baseContext.ecosService.getSessionsActives().subscribe(data => {
     baseContext.userService.nbr_sessions = data.length;
     });*/
  }

  private attributePostFormation() {
    this.components[2].childrens = [];
    for (let i = 0; i < this.teacher.coordinateur_post_formations.length; i++) {
      this.components[2].childrens.push({
        name: this.teacher.coordinateur_post_formations[i].label,
        url: "/attributions/validatedInscriptions/" + this.teacher.coordinateur_post_formations[i].id_formation
      })
    }
    console.log(this.components);
  }
}

export class NavigationMain {
  public name: string;
  public icon: string;
  public active?: string;
  public childrens?: ChildrenNavigation[] = [];
  public url?: string;
  public visible?: boolean;
  public notification?: number;
}

export class ChildrenNavigation {
  public name: string;
  public active?: string;
  public url: string;
  public notification?: number;
}


