/**
 * Created by Abbes on 04/08/2017.
 */
import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {ConstructionComponent} from "./Construction/contruction.component";

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        component: ConstructionComponent,
        path: 'construction'
      },

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ErrorModuleRouting {
}
