/**
 * Created by Abbes on 10/07/2017.
 */
/**
 * Created by Abbes on 30/06/2017.
 */
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {Evaluation} from "../models/stage/Evaluation";
import {Critere} from "../models/stage/Critere";
import {ResultGroupe} from "../models/stage/ResultGroupe";
import {environment} from "../../../environments/environment";
import {GCompetanceClinique} from "../models/stage/GCompetanceClinique";
import {CriterePortfolio} from "../models/stage/CriterePortfolio";
import {CompetanceClinique} from "../models/stage/CompetanceClinique";

/**
 * Created by Abbes on 30/06/2017.
 */
@Injectable()
export class StageService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  public context: any;

  getStudentsAffected() {
    const url = environment.baseUrl + "/teacher/me/stage/students";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllCriteres() {
    const url = environment.baseUrl + "/stage/critere/all";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getEvaluationById(evaluationId: number) {
    const url = environment.baseUrl + "/stage/evaluation/" + evaluationId;
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  evaluateStage(evaluation: Evaluation, criteres: Critere[]) {
    const url = environment.baseUrl + "/teacher/me/stage/evaluate";
    return this.http.post<any>(url, {
      'evaluation': evaluation,
      'criteres': criteres
    })
      .pipe(catchError(this.handleErrors));
  }

  addEcosNotePerPeriode(studentId: number, periodeId: number, note: number) {

    const url = environment.baseUrl + "/teacher/me/stage/evaluate-ecos";
    return this.http.post<any>(url, {
      'periodeId': periodeId,
      'studentId': studentId,
      'note': note
    })
      .pipe(catchError(this.handleErrors));
  }

  getGroupesOfJury(id_teacher) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/jury/groupesByJury";
    return this.http.get<any>(url, {
      headers: headers
    });
  }

  getStudentsByGroupe(groupe_id: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/jury/studentsByGroupe/" + groupe_id;
    return this.http.get<any>(url, {
      headers: headers
    });
  }

  getStudentsByGroupeAndByPeriode(groupe_id: number, periode_id: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/jury/studentsbyGroupeAndPeriode/" + groupe_id + "/" + periode_id;
    return this.http.get<any>(url, {
      headers: headers
    });
  }

  getResultGroupesByGroupeAndByPeriode(groupe_id: number, periode_id: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/jury/resultGroupesByGroupeAndPeriode/" + groupe_id + "/" + periode_id;
    return this.http.get<any>(url, {
      headers: headers
    });
  }

  getNumberStudentsByGroupeAndByPeriode(groupe_id: number, periode_id: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/jury/studentsNumberByGroupeAndPeriode/" + groupe_id + "/" + periode_id;
    return this.http.get<any>(url, {
      headers: headers
    });
  }

  getResultGroupesByGroupe(groupe_id: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/jury/resultGroupesByGroupe/" + groupe_id;
    return this.http.get<any>(url, {
      headers: headers
    });
  }

  getResultGroupesByStudentByIndexInGroupe(groupe_id: number, student_index: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/jury/resultGroupesByStudentIndexInGroupe/" + groupe_id + "/" + student_index;
    return this.http.get<any>(url, {
      headers: headers
    });
  }

  getResultGroupesByStudentByIndexInGroupeAndPeriod(sessionStageId: number, groupe_id: number, student_index: number, periode_id: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/jury/resultGroupesByStudentIndexInGroupeAndPeriode/" + student_index;
    return this.http.post<any>(url, {
      groupe_id: groupe_id,
      periode_id: periode_id,
      session_stage_id: sessionStageId
    }, {
      headers: headers
    });
  }

  submitDecisionJury(result_groupe: ResultGroupe) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/jury/submitDecision";
    return this.http.post<any>(url, result_groupe, {
      headers: headers
    });
  }

  getAllGCompetances() {
    const url = environment.baseUrl + "/stage/ecos/gcompetance-clinique/all";
    return this.http.get<any>(url);
  }

  evaluateEcos(evaluationId: number, etudiant_id: number, gcompetances: GCompetanceClinique[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/evaluate-grille-ecos/" + evaluationId + "/student/" + etudiant_id;
    return this.http.post<any>(url, gcompetances, {
      headers: headers
    });
  }

  getAllPortfolioCriteres() {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/stage/portfolio/criteres";
    return this.http.get<any>(url, {
      headers: headers
    });
  }

  evaluatePortfolio(evaluationId: number, studentId: number, globalEvaluation: number, criteres: CriterePortfolio[]) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/stage/evaluate-portfolio/" + evaluationId + "/student/" + studentId;
    return this.http.post<any>(url, {
      global_validation: globalEvaluation,
      criteres: criteres
    }, {
      headers: headers
    });
  }

  getAllCompetancesClinique() {
    const url = environment.baseUrl + "/stage/ecos/competance-clinique/all";
    return this.http.get<CompetanceClinique[]>(url);
  }
}
