/**
 * Created by Abbes on 10/07/2017.
 */
/**
 * Created by Abbes on 30/06/2017.
 */
import {Injectable} from '@angular/core';
import {GenericService} from './generic.service';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Teacher} from "../models/Teacher";
import {environment} from "../../../environments/environment";

/**
 * Created by Abbes on 30/06/2017.
 */
@Injectable()
export class InscriptionService extends GenericService {

  constructor(private http: HttpClient) {
    super();
  }


  registerTeacher(teacher: Teacher) {
    const url = environment.baseUrl + "/teacher/create";
    const headers = this.headers;
    return this.http.post<any>(url, teacher).pipe(catchError(this.handleErrors));
  }
}
