import {Component, OnInit} from '@angular/core';
import {StageService} from "../../shared/services/stage.service";
import {Groupe} from "../../shared/models/stage/Groupe";
import {Subscription} from "rxjs";
import {Periode} from "../../shared/models/stage/Periode";
import {ResultGroupe} from "../../shared/models/stage/ResultGroupe";
import {Router} from "@angular/router";
import {Evaluation} from "../../shared/models/stage/Evaluation";
import {Evaluation_Critere} from "../../shared/models/stage/Evaluation_Critere";
import {CompetanceClinique} from "../../shared/models/stage/CompetanceClinique";
import {EvaluationCClinique} from "../../shared/models/stage/EvaluationCClinique";
import {CriterePortfolio} from "../../shared/models/stage/CriterePortfolio";
import {EvaluationPortfolio} from "../../shared/models/stage/EvaluationPortfolio";

declare let swal: any;

@Component({
  selector: 'app-evaluer-etudiant',
  templateUrl: './evaluer-etudiant.component.html',
  styleUrls: ['./evaluer-etudiant.component.css']
})
export class EvaluerEtudiantComponent implements OnInit {
  context: any;

  student_aliases: string [] = [];
  selectedGroupe: Groupe;
  selectedPeriodeId: number;
  selectedPeriode: Periode;
  periodesNumber: number = 1;
  selectedEtudiantIndex: number;
  resultGroupes: ResultGroupe[] = [];
  array_rows: Array_Row[] = [];
  selectedEtudiant = false;
  busy: Subscription;
  no_speriodes_needed: number = 0;
  evaluations: Evaluation[] = [];
  evalsJury: any[] = [];
  criteresEcos: CompetanceClinique[] = [];
  criteresPortfolio: CriterePortfolio[] = [];

  constructor(private stageService: StageService, private router: Router) {
    this.context = this.stageService.context;
    console.log(this.stageService.context);
    if (!this.context)
      this.router.navigateByUrl('/stage/jury');
    else {
      this.selectedEtudiant = this.context["selectedEtudiant"];
      this.selectedGroupe = this.context["selectedGroupe"];
      this.resultGroupes = this.context["resultGroupes"];
      this.selectedPeriodeId = this.context["selectedPeriodeId"];
      this.selectedPeriode = this.context["selectedPeriode"];
      this.student_aliases = this.context["student_aliases"];
      this.periodesNumber = Number(this.selectedPeriode.label.split(" ")[1]);
      this.array_rows = this.context["array_rows"];
      this.criteresEcos = this.context["criteresEcos"];
      this.criteresPortfolio = this.context["criteresPortfolio"];

      console.log(this.criteresPortfolio);
      const that = this;
      setTimeout(function () {
        let j = 0;
        for (let critere_level of that.selectedGroupe.session_stage.niveau.criteres_niveaux) {
          that.array_rows[j].significations = [];
          for (const signfication of critere_level.critere.signficiations_criteres) {
            that.array_rows[j].significations.push(signfication.description);
          }
          j += 1;
        }
      }, 100);
      this.selectedEtudiantIndex = this.context["selectedEtudiantIndex"];
      this.getAllResultGroupesByStudentIndexInGroupeAndPeriode(this.selectedGroupe.session_stage_id, this.selectedGroupe.groupe_id,
        this.selectedEtudiantIndex, this.selectedPeriodeId);
    }
  }

  ngOnInit() {

    console.log(this.selectedGroupe.session_stage.periodes);

  }

  getAllResultGroupesByStudentIndexInGroupeAndPeriode(sessionStageId: number, groupe_id: number, selectedEtudiantIndex: number, periode_id: number) {
    this.busy = this.stageService.getResultGroupesByStudentByIndexInGroupeAndPeriod(sessionStageId, groupe_id, selectedEtudiantIndex, periode_id).subscribe(
      (data) => {
        let resultGroupes = data["resultGroups"];
        if (resultGroupes[this.periodesNumber - 1].decision != 0) {
          this.router.navigateByUrl('/stage/jury');
        }
        let evaluations = data["evaluations"];
        this.evaluations = evaluations;
        this.calculateNotes();
        for (let r of resultGroupes) {
          if (!r["evaluations"])
            r["evaluations"] = [];
        }
        for (let e of evaluations) {
          for (let r of resultGroupes) {
            if (e["periode_stage"]["periode_id"] == r["periode_id"])
              r["evaluations"].push(e);
          }
        }
        this.selectedGroupe.speriode_labels = [];
        let baseContext = this;
        // this.resultGroupes.forEach((rg,indexr)=>{
        baseContext.no_speriodes_needed = 0;
        let x = 1;
        baseContext.selectedGroupe.session_stage.periodes.slice(0, baseContext.periodesNumber).forEach((periode, index) => {
          periode.no_speriodes = resultGroupes[index].evaluations.length;
          baseContext.no_speriodes_needed += periode.no_speriodes;
          for (let j = 1; j <= periode.no_speriodes; j += 1) {
            this.selectedGroupe.speriode_labels.push("Éval " + x);
            x += 1;
          }
        });
        let arr = [];
        let criteres_count = this.array_rows.length;
        this.resultGroupes = resultGroupes;
        this.array_rows.forEach(r => {
          r.values = [];
          r.eval_jury = 0;
        });
        this.resultGroupes.forEach((rg, index) => {
          rg.evaluations.forEach((evaluation, ind) => {
              for (let i = 0; i < criteres_count; i += 1)
                baseContext.array_rows[i].values.push(
                  evaluation.evaluations_criteres[i] && index < baseContext.periodesNumber ?
                    evaluation.evaluations_criteres[i].value : " ");
              baseContext.evalsJury.push(false);
            }
          );
        });

      }
    )

  }

  getValueByEvaluation(evaluationEcos: EvaluationCClinique, competance_clinique_id: number) {
    if (!evaluationEcos)
      return -1;

    const indexCompetance = evaluationEcos.validations.map(item => {
      return item.competance_clinique_id
    }).indexOf(competance_clinique_id);

    return indexCompetance !== -1 ? evaluationEcos.validations[indexCompetance].value : -1;
  }

  getValidationPortfolio(evaluationPortfolio: EvaluationPortfolio, criterePortfolioId: number) {
    if (!evaluationPortfolio) {
      return null;
    }

    const indexValidation = evaluationPortfolio.validations.map(
      item => {
        return item.critere_portfolio_id;
      }
    ).indexOf(criterePortfolioId);

    if (indexValidation === -1) {
      return null;
    }

    return evaluationPortfolio.validations[indexValidation];
  }


  calculateNotes() {
    console.log(this.evaluations);

    this.criteresPortfolio.forEach(
      critere => {
        critere.validations = [];
        this.evaluations.forEach(
          evaluation => {
            critere.validations.push(this.getValidationPortfolio(evaluation.evaluation_portfolio, critere.critere_portfolio_id));
          }
        )
      }
    );

    console.log(this.criteresPortfolio);
    this.criteresEcos.forEach(
      critere => {
        critere.notes = [];
        this.evaluations.forEach(
          evaluation => {
            critere.notes.push(this.getValueByEvaluation(evaluation.ecos_evaluation, critere.competance_clinique_id));
          }
        )
      }
    )
  }


  submit() {
    let baseContext = this;
    console.log(baseContext.resultGroupes[baseContext.periodesNumber - 1]);
    if (baseContext.resultGroupes[baseContext.periodesNumber - 1].decision == 0) {
      swal("Erreur", "Merci de préciser votre decision d'abord.", "error");
      return;
    }

    if (baseContext.resultGroupes[baseContext.periodesNumber - 1].portfolio === 0 && !baseContext.resultGroupes[baseContext.periodesNumber - 1].comment_portfolio) {
      swal("Erreur", "Merci de précision votre motif de refus du portfolio", "error");
      return;
    }
    this.resultGroupes[baseContext.periodesNumber - 1].evaluations_jury = [];
    for (let array_row of this.array_rows) {
      if (array_row.eval_jury == 0) {
        swal("Erreur", "Merci de préciser votre evaluation des critères.", "error");
        return;
      }
      let ec = new Evaluation_Critere();
      ec.critere_id = array_row.critere_id;
      ec.value = array_row.eval_jury;
      baseContext.resultGroupes[baseContext.periodesNumber - 1].evaluations_jury.push(ec);
    }
    swal({
        title: "Vous êtes sûr?",
        text: "Voulez vous soumettre définitivement votre decision?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#EF5350",
        confirmButtonText: "Oui, envoyer!",
        cancelButtonText: "Non, annuler!",
        closeOnConfirm: true,
        closeOnCancel: true
      },
      function (isConfirm) {
        if (isConfirm) {
          baseContext.busy = baseContext.stageService.submitDecisionJury(baseContext.resultGroupes[baseContext.periodesNumber - 1]).subscribe((data) => {
            swal("Succées", "Evaluation effecuté avec succées, Etudiant Suivant", "success");
            baseContext.nextStudent();
          }, (error) => {
            swal("Erreur", "Erreur leur de la soumission", "error")

          })
        }
      });

  }

  nextStudent() {
    if (this.selectedEtudiantIndex < (this.student_aliases.length - 1)) {
      this.selectedEtudiantIndex += 1;
      this.getAllResultGroupesByStudentIndexInGroupeAndPeriode(this.selectedGroupe.session_stage_id, this.selectedGroupe.groupe_id, this.selectedEtudiantIndex, this.selectedPeriodeId);
    }
  }

  prevStudent() {
    if (this.selectedEtudiantIndex > 0) {

      this.selectedEtudiantIndex -= 1;
      this.getAllResultGroupesByStudentIndexInGroupeAndPeriode(this.selectedGroupe.session_stage_id, this.selectedGroupe.groupe_id, this.selectedEtudiantIndex, this.selectedPeriodeId);
    }
  }

  eval(row
         :
         Array_Row, number
         :
         number
  ) {
    row.eval_jury = number;
  }
}

class Array_Row {
  label_critere = "";
  critere_id = 0;
  ponderation = 0;
  values = [];
  eval_jury: number;
  significations: string[] = [];

}
