import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Conversation} from "../models/Conversation";
import {UserService} from "./user.service";
import {environment} from "../../../environments/environment";

@Injectable()
export class ConversationService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService, private userService: UserService) {
    super();
  }

  getTeacherAllConversations() {
    const url = environment.baseUrl + '/conversation/teacher';
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getConversationById(id_conversation: number) {
    const url = environment.baseUrl + '/conversation/' + id_conversation;
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getConversationMessages(id_conversation: number) {
    const url = environment.baseUrl + '/conversation/' + id_conversation + '/message';
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  startConversationUnregistered(isTeacher: number, email: string, nom: string, id_admin: number, CIN?: string, passport?: string, content?: string, topic?: string) {
    const url = environment.baseUrl + '/conversation/startUnregistered';
    console.log(nom);
    return this.http.post<any>(url, {
      isTeacher: isTeacher,
      CIN: CIN,
      passport: passport,
      nom: nom,
      email: email,
      id_Admin: id_admin,
      content: content,
      topic: topic
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  startConversation(id_teacher: number, id_admin: number, content?: string, topic?: string) {
    const url = environment.baseUrl + '/conversation/start';
    return this.http.post<any>(url, {
      id_Teacher: id_teacher,
      id_Admin: id_admin,
      content: content,
      topic: topic
    })
      .pipe(catchError(this.handleErrors));
  }

  addConversationMessage(conversation: Conversation, content: string) {
    const url = environment.baseUrl + '/conversation/' + conversation.id_Conversation + '/message/add';
    return this.http.post<any>(url, {
      id_Teacher: conversation.id_Teacher,
      id_Admin: null,
      content: content
    })
      .pipe(catchError(this.handleErrors));
  }

  getConversationByStatus(status: number) {
    const url = environment.baseUrl + '/conversation/teacher/status/' + status;
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  updateConversationStatus(conversation: Conversation, status: number) {
    const url = environment.baseUrl + '/conversation/' + conversation.id_Conversation + '/status/{status}';
    return this.http.post<any>(url, {
      status: status
    })
      .pipe(catchError(this.handleErrors));
  }

  setConversationViewed(conversation: Conversation) {
    const url = environment.baseUrl + '/conversation/' + conversation.id_Conversation + '/viewed';
    return this.http.put<any>(url, {})
      .pipe(catchError(this.handleErrors));
  }

  getConversationsCount() {
    const url = environment.baseUrl + '/conversation/teacher/count';
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }
}
