import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ResultMasteresComponent} from "./result-masteres/result-masteres.component";
import {MenuComponent} from "./menu/menu.component";

export const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      {
        path: '',
        redirectTo: './result-masteres',
        pathMatch: 'full'
      },
      {
        path: 'result-masteres',
        component: ResultMasteresComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PortfolioModuleRouting {
}
