import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {GenericService} from './generic.service';
import {Injectable} from '@angular/core';
import {Credentials} from "../models/credentials";
import {StorageService} from "./storage.service";
import {environment} from "../../../environments/environment";


@Injectable()
export class AuthService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }


  login(credentials: Credentials) {
    const url = environment.baseUrl + '/auth/login/teacher';
    const headers = this.headers;
    return this.http.post<any>(url, {
      "email": credentials.login,
      "password": credentials.password
    }).pipe(catchError(this.handleErrors));
  }


  isLoggedIn() {
    return this.stoarageService.read("token") != null;
  }

}
