import {NgModule} from "@angular/core";

import {AppComponent} from "./app.component";
import {LoginComponent} from "./login/login.component";
import {AppRoutingModule} from "./app.routing";
import {FullLayoutComponent} from "./layouts/full-layout.component";
import {AuthService} from "./shared/services/auth.service";
import {StorageService} from "./shared/services/storage.service";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {CommonModule, HashLocationStrategy, LocationStrategy} from "@angular/common";
import {NgBusyModule} from "ng-busy";
import {FormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {InscriptionService} from "./shared/services/inscription.service";
import {SharedModule} from "./shared/shared.module";
import {UserService} from "./shared/services/user.service";
import {NotFoundComponent} from "./error/not-found.component";
import {ReclamationService} from "./shared/services/reclamation.service";
import {ErrorModule} from "./error/error.module";
import {ConversationService} from "./shared/services/conversation.service";
import {NotificationService} from "./shared/services/notification.service";
import {HeaderNotificationComponent} from "./layouts/notifications/header-notification.component";
import {PartielNotificationService} from "./shared/services/partielNotification.service";
import {TeacherFileService} from "./shared/services/teacher-file.service";
import {ManageTeacherFileModule} from "./teacher-file/teacher-file.module";
import {SupportModule} from "./support/support.module";
import {SharedService} from "./shared/services/shared.service";
import {StageService} from "./shared/services/stage.service";
import {PostGraduateTrainingService} from "./shared/services/post-graduate-training.service";
import {AttributionsModule} from "./attributions/attributions.module";
import {InscriptionsModule} from "./inscriptions/inscriptions.module";
import {TokenInterceptor} from "./shared/token.interceptor";
import {PortfolioModule} from "./portfolio/portfolio.module";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FullLayoutComponent,
    NotFoundComponent,
    HeaderNotificationComponent
  ],
  imports: [
    SharedModule,
    NgBusyModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ManageTeacherFileModule,
    SupportModule,
    // InscriptionsModule,
    ErrorModule,
    AttributionsModule,
    InscriptionsModule,
    PortfolioModule

  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthService,
    StorageService,
    InscriptionService,
    TeacherFileService,
    UserService,
    ReclamationService,
    ConversationService,
    NotificationService,
    PartielNotificationService,
    SharedService,
    StageService,
    PostGraduateTrainingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
