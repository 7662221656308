import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {ListDemandPostTrainingComponent} from "./list-demand-post-training/list-demand-post-training.component";
import {ListValidatedInscriptionsComponent} from "./list-validated-inscriptions/list-validated-inscriptions.component";
import {ManageFormationsComponent} from "./manage-formations/manage-formations.component";

export const routes: Routes = [
  {
    path: '',
    component: ManageFormationsComponent,
  },
  {
    path: ':sessionId',
    children: [
      {
        path: 'formation/:formationId',
        children: [
          {
            path: 'list-demands',
            component: ListDemandPostTrainingComponent
          },
          {
            path: 'list-inscrits',
            component: ListValidatedInscriptionsComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AttributionsRoutingModule {
}
