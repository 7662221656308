import {Periode} from "./Periode";

export class S_Periode {
  public s_periode_id: number;
  public periode_id: number;

  public start_date: string;
  public end_date: string;

  public end_middle_date?: string;
  public start_middle_date?: string;

  public periode: Periode = new Periode();

}
