import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs/Subscription";
import {InitialPreviewConfig, Utils} from "../../shared/utils";
import {StorageService} from "../../shared/services/storage.service";
import {UserService} from "../../shared/services/user.service";
import {Router} from "@angular/router";
import {Teacher} from "../../shared/models/Teacher";
import {environment} from "../../../environments/environment";

declare let jQuery;
declare let swal;

@Component({
  templateUrl: 'photo.component.html'
})
export class PhotoComponent implements OnInit {
  busy: Subscription;

  teacher: Teacher;

  constructor(private storageService: StorageService, private userServices: UserService,
              private router: Router) {
    this.teacher = this.userServices.loggedUser;
  }

  ngOnInit(): void {
    if (!this.teacher.img) {
      Utils.initializeUploadFile(environment.baseUrl + "/teacher/" + this.userServices.loggedUser.id_Teacher + "/photo/upload",
        this.userServices.getTokent(), ".file-input-teacher-photo", 1);
    } else {
      this.initStudentPhoto();
    }
    if ((this.teacher.cin && !this.teacher.cin.imgs) && (this.teacher.passport && !this.teacher.passport.imgs)) {
      Utils.initializeUploadFile(environment.baseUrl + "/teacher/" + this.userServices.loggedUser.id_Teacher + "/cin/upload",
        this.userServices.getTokent(), ".file-input-teacher-cin", 2);
      if (this.teacher.cin) {
        this.teacher.cin.imgs = [];
      } else if (this.teacher.passport) {
        this.teacher.passport.imgs = [];
      }
    } else {
      this.initStudentCin();
    }
    if (!this.teacher.extrait_naissance) {
      Utils.initializeUploadFile(environment.baseUrl + "/teacher/" + this.userServices.loggedUser.id_Teacher + "/extrait-naissance/upload",
        this.userServices.getTokent(), ".file-input-teacher-extrait-naissance", 1);
    } else {
      this.initStudentExtraitNaissance();
    }

    /*if (!this.teacher.attestation_orientation) {
     Utils.initializeUploadFile(environment.baseUrl + "/teacher/" + this.userServices.loggedUser.id_teacher + "/attestation-orientation/upload",
     this.userServices.getTokent(), ".file-input-teacher-attestation-orientation", 1);
     } else {
     this.initStudentAttestationOrientation();
     }*/

    const baseContext = this;
    jQuery('.file-input-teacher-photo').change(function () {
      console.log('file input change');
    }).on('fileuploaded', function (event, data, previewId, index) {
      baseContext.teacher.img = baseContext.userServices.loggedUser.img = data.response.media;
      console.log("fileuploaded");
      baseContext.storageService.write("teacher", baseContext.teacher);
      swal({
        title: "Succés!",
        text: 'Vous avez ajouté une photo',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    }).on('filedeleted', function (event, key, jqXHR, data) {
      baseContext.teacher.img = baseContext.userServices.loggedUser.img = null;
      baseContext.storageService.write("teacher", baseContext.teacher);
      swal({
        title: "Succés!",
        text: 'Vous avez supprimé votre photo',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    });

    jQuery('.file-input-teacher-extrait-naissance').change(function () {
      console.log('file input change');
    }).on('fileuploaded', function (event, data, previewId, index) {
      baseContext.teacher.extrait_naissance = baseContext.userServices.loggedUser.extrait_naissance = data.response.media;
      console.log("fileuploaded");
      baseContext.storageService.write("teacher", baseContext.teacher);
      swal({
        title: "Succés!",
        text: 'Vous avez ajouté une photo de votre extrait de naissance',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    }).on('filedeleted', function (event, key, jqXHR, data) {
      baseContext.teacher.extrait_naissance = baseContext.userServices.loggedUser.extrait_naissance = null;
      baseContext.storageService.write("teacher", baseContext.teacher);
      swal({
        title: "Succés!",
        text: 'Vous avez supprimé votre extrait de naissance',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    });
    /*jQuery('.file-input-teacher-attestation-orientation').change(function () {
     console.log('file input change');
     }).on('fileuploaded', function (event, data, previewId, index) {
     baseContext.teacher.attestation_orientation =
     baseContext.userServices.loggedUser.attestation_orientation = data.response.media;
     console.log("fileuploaded");
     baseContext.storageService.write("teacher", baseContext.teacher);
     swal({
     title: "Succés!",
     text: 'Vous avez ajouté une photo de votre attestation d\'orientation',
     confirmButtonColor: "#66BB6A",
     type: "success"
     });
     }).on('filedeleted', function (event, key, jqXHR, data) {
     baseContext.teacher.attestation_orientation = baseContext.userServices.loggedUser.attestation_orientation = null;
     baseContext.storageService.write("teacher", baseContext.teacher);
     swal({
     title: "Succés!",
     text: 'Vous avez supprimé votre attestation d\'orientation',
     confirmButtonColor: "#66BB6A",
     type: "success"
     });
     });*/

    jQuery('.file-input-teacher-cin').change(function () {
      console.log('file input change');
    }).on('fileuploaded', function (event, data, previewId, index) {
      const imgs = [];
      if (baseContext.teacher.cin) {
        baseContext.teacher.cin.imgs.push(data.response.media);
      } else if (baseContext.teacher.passport) {
        baseContext.teacher.passport.imgs.push(data.response.media);
      }
      baseContext.storageService.write("teacher", baseContext.teacher);
      swal({
        title: "Succés!",
        text: 'Vous avez ajouté une photo de votre CIN',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
      baseContext.userServices.loggedUser = baseContext.teacher;

    }).on('filedeleted', function (event, key, jqXHR, data) {
      const medias = [];
      let imgs = [];
      if (baseContext.teacher.cin) {
        imgs = baseContext.teacher.cin.imgs;
      } else if (baseContext.teacher.passport) {
        imgs = baseContext.teacher.passport.imgs;
      }
      imgs.forEach(function (img_cin) {
        medias.push(img_cin.path);
      });
      const index = medias.indexOf(jqXHR.responseJSON.media, 0);
      if (index > -1) {
        imgs.splice(index, 1);
      }
      if (baseContext.teacher.cin) {
        baseContext.teacher.cin.imgs = imgs;
      } else if (baseContext.teacher.passport) {
        baseContext.teacher.passport.imgs = imgs;
      }
      baseContext.userServices.loggedUser = baseContext.teacher;
      baseContext.storageService.write("teacher", baseContext.teacher);
      swal({
        title: "Succés!",
        text: 'Vous avez supprimé votre CIN',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    });
  }

  private initStudentPhoto() {
    const medias = [];
    const inputMedias = [];
    const initialPreviewConfig: InitialPreviewConfig[] = [];
    const teacherImg = this.teacher.img;
    medias.push(this.teacher.img.path);
    inputMedias.push(environment.baseUrl + '/' + teacherImg.path);
    initialPreviewConfig.push({
      type: Utils.loadTypeFromExtension(teacherImg.path.substr(teacherImg.path.indexOf('.') + 1)),
      filetype: Utils.loadFileTypeFromExtension(teacherImg.path.substr(teacherImg.path.indexOf('.') + 1)),
      key: teacherImg.id_Teacher_Photo,
      url: environment.baseUrl + '/' + teacherImg.path + '/delete',
      size: teacherImg.size,
      downloadUrl: environment.baseUrl + '/' + teacherImg.path
    });
    Utils.initializeUploadFile(environment.baseUrl + "/teacher/" + this.userServices.loggedUser.id_Teacher + "/photo/upload",
      this.userServices.getTokent(), ".file-input-teacher-photo", 1, inputMedias, initialPreviewConfig);
  }

  private initStudentExtraitNaissance() {
    const medias = [];
    const inputMedias = [];
    const initialPreviewConfig: InitialPreviewConfig[] = [];
    const teacherImg = this.teacher.extrait_naissance;
    medias.push(this.teacher.extrait_naissance.path);
    inputMedias.push(environment.baseUrl + '/' + teacherImg.path);
    initialPreviewConfig.push({
      type: Utils.loadTypeFromExtension(teacherImg.path.substr(teacherImg.path.indexOf('.') + 1)),
      filetype: Utils.loadFileTypeFromExtension(teacherImg.path.substr(teacherImg.path.indexOf('.') + 1)),
      key: teacherImg.id_Teacher_Extrait_Naissance,
      url: environment.baseUrl + '/' + teacherImg.path + '/delete',
      size: teacherImg.size,
      downloadUrl: environment.baseUrl + '/' + teacherImg.path
    });
    Utils.initializeUploadFile(environment.baseUrl + "/teacher/" + this.userServices.loggedUser.id_Teacher + "/photo/upload",
      this.userServices.getTokent(), ".file-input-teacher-extrait-naissance", 1, inputMedias, initialPreviewConfig);
  }

  /*private initStudentAttestationOrientation() {
   const medias = [];
   const inputMedias = [];
   const initialPreviewConfig: InitialPreviewConfig[] = [];
   const teacherImg = this.teacher.attestation_orientation;
   medias.push(this.teacher.attestation_orientation.path);
   inputMedias.push(environment.baseUrl + '/' + teacherImg.path);
   initialPreviewConfig.push({
   type: Utils.loadTypeFromExtension(teacherImg.path.substr(teacherImg.path.indexOf('.') + 1)),
   filetype: Utils.loadFileTypeFromExtension(teacherImg.path.substr(teacherImg.path.indexOf('.') + 1)),
   key: teacherImg.id_Student_Attestation_Orientation,
   url: environment.baseUrl + '/' + teacherImg.path + '/delete',
   size: teacherImg.size
   });
   Utils.initializeUploadFile(environment.baseUrl + "/teacher/" + this.userServices.loggedUser.id_Teacher + "/photo/upload",
   this.userServices.getTokent(), ".file-input-teacher-attestation-orientation", 1, inputMedias, initialPreviewConfig);
   }*/

  private initStudentCin() {
    const medias = [];
    const inputMedias = [];
    const initialPreviewConfig: InitialPreviewConfig[] = [];
    let imgs = [];
    if (this.teacher.cin) {
      imgs = this.teacher.cin.imgs;
    } else if (this.teacher.passport) {
      imgs = this.teacher.passport.imgs;
    }
    imgs.forEach(function (img) {
      medias.push(img.path);
      inputMedias.push(environment.baseUrl + '/' + img.path);
      initialPreviewConfig.push({
        type: Utils.loadTypeFromExtension(img.path.substr(img.path.indexOf('.') + 1)),
        filetype: Utils.loadFileTypeFromExtension(img.path.substr(img.path.indexOf('.') + 1)),
        key: img.id_Student_Cin,
        url: environment.baseUrl + '/' + img.path + '/delete',
        size: img.size,
        downloadUrl: environment.baseUrl + '/' + img.path
      });
    });
    Utils.initializeUploadFile(environment.baseUrl + "/teacher/" + this.userServices.loggedUser.id_Teacher + "/cin/upload",
      this.userServices.getTokent(), ".file-input-teacher-cin", 2, inputMedias, initialPreviewConfig);
  }

}
