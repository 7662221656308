import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Evaluation} from "../../shared/models/stage/Evaluation";
import {ActivatedRoute, Router} from "@angular/router";
import {StageService} from "../../shared/services/stage.service";
import {environment} from "../../../environments/environment";
import {CriterePortfolio} from "../../shared/models/stage/CriterePortfolio";

declare let swal: any;

@Component({
  selector: 'app-evaluation-portfolio',
  templateUrl: './evaluation-portfolio.component.html',
  styleUrls: ['./evaluation-portfolio.component.css']
})
export class EvaluationPortfolioComponent implements OnInit {

  busy: Subscription;
  evaluationId: number;
  evaluation: Evaluation = new Evaluation();
  baseUrl: string = environment.baseUrl;
  criteres: CriterePortfolio[];

  globalEvaluation: number;

  validations: any[] = [
    {
      label: 'Oui',
      value: 1
    },
    {
      label: 'Non',
      value: 0
    }
  ];


  constructor(private route: ActivatedRoute,
              private stageService: StageService,
              private router: Router) {

    this.evaluationId = parseInt(this.route.snapshot.paramMap.get('evaluationId'));

  }

  ngOnInit() {
    this.getAllCriteres();
    this.getEvaluation();

  }

  getAllCriteres() {
    this.busy = this.stageService.getAllPortfolioCriteres()
      .subscribe(
        (data) => {
          this.criteres = data;
        },
        (error) => {

        }
      )
  }

  getEvaluation() {
    this.busy = this.stageService.getEvaluationById(this.evaluationId)
      .subscribe(
        (data) => {
          this.evaluation = data;
        },
        (error) => {

        }
      )
  }

  isEmptyChamps() {
    for (let i = 0; i < this.criteres.length; i++) {
      if ((this.criteres[i].has_document && this.criteres[i].document_number < 0)
        || this.criteres[i].validation === undefined) {
        return true;
      }
    }
    return false;
  }

  submitEvaluation() {

    if (!this.globalEvaluation || this.isEmptyChamps()) {
      swal('Warning', 'Vérifier que les champs sont remplis', 'warning');
      return;
    }

    this.busy = this.stageService.evaluatePortfolio(this.evaluationId, this.evaluation.etudiant_id, this.globalEvaluation, this.criteres)
      .subscribe(
        (data) => {
          swal("Succées", "Evaluation faite avec succées", "success");
          this.router.navigate(['/stage/list-affectation']);
        },
        (error) => {

        }
      );
  }

}
