/**
 * Created by Abbes on 28/08/2017.
 */
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {environment} from "../../../environments/environment";

/**
 * Created by AHMED on 04/08/2017.
 */
@Injectable()
export class PartielNotificationService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }


  getNewNotifications() {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/partiel-notification/new";

    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }


  viewSubsection(subSectionId: number) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/student/me/partiel-notification/view/subsection/" + subSectionId;

    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }
}
