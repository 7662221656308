import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {UserService} from "./user.service";
import {environment} from "../../../environments/environment";

@Injectable()
export class NotificationService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService, private userService: UserService) {
    super();
  }


  getAllStudentNotifications() {
    const url = environment.baseUrl + '/notification/student';
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getNotificationById(id_notification: number) {
    const url = environment.baseUrl + '/notification/' + id_notification;
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  setNotficationViewed(id_notification: number) {
    const url = environment.baseUrl + '/notification/' + id_notification + '/student/' + this.userService.loggedUser.id_Teacher + '/viewed';
    return this.http.put<any>(url, {})
      .pipe(catchError(this.handleErrors));

  }
}
