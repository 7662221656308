import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs/Rx";
import {Inscription_Formation} from "../../shared/models/inscription_formation";
import {Formation} from "../../shared/models/formation";
import {ActivatedRoute} from "@angular/router";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {Utils} from "../../shared/utils";
import {Teacher} from "../../shared/models/Teacher";
import {UserService} from "../../shared/services/user.service";
import {Student} from "../../shared/models/student";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-list-validated-inscriptions',
  templateUrl: './list-validated-inscriptions.component.html',
  styleUrls: ['./list-validated-inscriptions.component.css']
})
export class ListValidatedInscriptionsComponent implements OnInit {

  busy: Subscription;
  inscriptionsFormation: Inscription_Formation[] = [];

  formationId: number;
  sessionFormationId: number;
  formation: Formation = new Formation();

  teacher: Teacher = new Teacher();

  mail: MailStructure = new MailStructure();

  selectedStudent: Student = new Student();

  mails: string[];

  constructor(private route: ActivatedRoute, private postGraduateTrainingService: PostGraduateTrainingService,
              private userService: UserService) {
    this.formationId = parseInt(this.route.snapshot.paramMap.get('formationId'));
    this.sessionFormationId = parseInt(this.route.snapshot.paramMap.get('sessionId'))
  }

  ngOnInit() {

    this.teacher = this.userService.loggedUser;
    this.loadFormation();
    this.loadCandidatsByFormation();

  }

  loadCandidatsByFormation() {
    this.busy = this.postGraduateTrainingService.getValidatedInscriptionsByFormation(this.sessionFormationId, this.formationId)
      .subscribe(
        (data: Inscription_Formation[]) => {
          console.log(data);
          this.inscriptionsFormation = data;
          Utils.initializeDataTables(20, 4);

        },
        (error) => {
          if (error.status === 401) {
            swal("Erreur", "Vous n'avez pas droit d'accéeder à cette formation", "error");
          }
        }
      )
  }

  loadFormation() {
    this.busy = this.postGraduateTrainingService.getFormationById(this.formationId)
      .subscribe(
        (data: Formation) => {
          this.formation = data;
        },
        (error) => {
          if (error.status === 401) {
            swal("Erreur", "Vous n'avez pas droit d'accéeder à cette formation", "error");
          }

        }
      )
  }

  openModalSending(index?: number) {
    jQuery("#modal_form_mail_sending").modal();

    if (index) {
      this.selectedStudent = this.inscriptionsFormation[index].student;
    }
  }

  sendMailAll() {
    jQuery("#modal_form_mail_sending").modal("hide");

    if (this.selectedStudent.id_student) {
      this.mails = [this.selectedStudent.email];
      this.selectedStudent = new Student();
    } else {
      const students: Student[] = this.inscriptionsFormation.map(
        inscription => {
          return inscription.student;
        }
      );
      this.mails = Utils.getMailsByListStudents(students);
    }
    this.busy = this.postGraduateTrainingService
      .sendMailByStudents(this.mail, this.mails)
      .subscribe(
        (data) => {
          this.mail = new MailStructure();
          swal('Succées', 'Votre mail à été envoyé avec succées', 'success');
        },
        (error) => {
          this.mail = new MailStructure();
          swal('Erreur', 'Votre mail n"a pas envoyé', 'error');
        }
      )
  }
}

export class MailStructure {
  public subject: string;
  public content: string;
}
