import {NgModule} from '@angular/core';
import {SharedModule} from "../shared/shared.module";
import {PortfolioModuleRouting} from "./portfolio.routing";
import {MenuComponent} from "./menu/menu.component";
import {ResultMasteresComponent} from "./result-masteres/result-masteres.component";

@NgModule({
  imports: [
    PortfolioModuleRouting,
    SharedModule,

  ],
  declarations: [
    MenuComponent,
    ResultMasteresComponent
  ]
})
export class PortfolioModule {
}
