import {Component, OnInit} from '@angular/core';
import {Groupe} from "../../shared/models/stage/Groupe";
import {StageService} from "../../shared/services/stage.service";
import {StorageService} from "../../shared/services/storage.service";
import {Subscription} from "rxjs";
import {Periode} from "../../shared/models/stage/Periode";
import {ResultGroupe} from "../../shared/models/stage/ResultGroupe";
import {Evaluation} from "../../shared/models/stage/Evaluation";
import {Utils} from "../../shared/utils";
import {Router} from "@angular/router";
import {CompetanceClinique} from "../../shared/models/stage/CompetanceClinique";
import {CriterePortfolio} from "../../shared/models/stage/CriterePortfolio";

declare let swal: any;

@Component({
  selector: 'app-evaluations-jury',
  templateUrl: './evaluations-jury.component.html',
  styleUrls: ['./evaluations-jury.component.css']
})
export class EvaluationsJuryComponent implements OnInit {

  today = new Date();
  groupes: Groupe[] = [];
  busy: Subscription;
  result_groupes: any = [];
  student_aliases: string [] = [];
  selectedGroupe: Groupe;
  selectedGroupeId: number;
  selectedPeriodeId: number;
  selectedPeriode: Periode;
  selectedEtudiantIndex: number;
  resultGroupes: ResultGroupe[] = [];
  evaluations: Evaluation[] = [];
  array_rows: Array_Row[] = [];
  selectedEtudiant = false;

  criteresEcos: CompetanceClinique[] = [];
  criteresPortfolio: CriterePortfolio[] = [];

  constructor(private stageService: StageService,
              private storageService: StorageService,
              private router: Router) {
  }

  ngOnInit() {
    // TODO ADD Select Groupe
    this.getAllGroupesOfJury();

    this.getAllGCompetances();
    this.getAllCriteresPortfolio();
  }

  getAllGroupesOfJury() {
    this.busy = this.stageService.getGroupesOfJury(this.storageService.read("teacher")["id_Teacher"]).subscribe(
      (data) => {
        this.groupes = data;
        /*if (this.groupes.length > 0)
          this.selectedGroupeId = this.groupes[0].groupe_id;
        else
          this.selectedGroupeId = null;*/
      }
    );
  }

  getAllCriteresPortfolio() {
    this.busy = this.stageService.getAllPortfolioCriteres()
      .subscribe(
        (data) => {
          this.criteresPortfolio = data;
        }
      )
  }


  getAllGCompetances() {
    this.busy = this.stageService.getAllCompetancesClinique()
      .subscribe(
        (data) => {
          this.criteresEcos = data;
        }
      )
  }

  getAllStudentsByGroupe(groupe_id: number) {
    this.busy = this.stageService.getStudentsByGroupe(groupe_id).subscribe(
      (data) => {
        //console.log(data)
      }
    );
  }

  getAllResultGroupesByGroupe(groupe_id: number) {
    this.busy = this.stageService.getResultGroupesByGroupe(groupe_id).subscribe(
      (data) => {
        let baseContext = this;
        // this.result_groupes = data;
        this.result_groupes = Object.keys(data).map(i => data);
        //console.log(this.result_groupes);
      }
    );
    // //console.log(this.result_groupes);
  }

  onSelectGroupe($event: number) {
    this.array_rows = [];
    // this.getAllResultGroupesByGroupe($event);
    this.selectedGroupe = this.groupes.find(x => x.groupe_id == $event);
    let i = 0;
    this.selectedGroupe.speriode_labels = [];
    this.selectedGroupe.session_stage.periodes.forEach(periode => {
      i += 1;
      periode.label = "Période " + i;
    });
    for (let critere_level of this.selectedGroupe.session_stage.niveau.criteres_niveaux) {
      let ar = new Array_Row();
      ar.label_critere = critere_level.critere.label;
      ar.critere_id = critere_level.critere.critere_id;
      ar.eval_jury = 0;
      ar.ponderation = critere_level.ponderation;
      this.array_rows.push(ar);
    }


    let baseContext = this;
    let currentIndex = this.selectedGroupe.session_stage.periodes.findIndex(p => {
      return (new Date(p.start_date) <= baseContext.today) && (new Date(p.end_date) >= baseContext.today)
    });
    // if (currentIndex == 0) {
    //   swal({
    //       title: "Erreur!",
    //       text: 'Vous ne pouvez évaluer aucune période.',
    //       type: "error",
    //       confirmButtonColor: "#66BB6A",
    //       confirmButtonText: "D'accord",
    //       closeOnConfirm: true,
    //     },
    //     function (isConfirm) {
    //       if (isConfirm) {
    //         baseContext.router.navigateByUrl('/')
    //       }
    //     });
    //   return;
    // }
    if (!this.selectedGroupe.session_stage.periodes || this.selectedGroupe.session_stage.periodes.length == 0) {
      swal({
          title: "Erreur!",
          text: 'Vous ne pouvez évaluer aucune période.',
          type: "error",
          confirmButtonColor: "#66BB6A",
          confirmButtonText: "D'accord",
          closeOnConfirm: true,
        },
        function (isConfirm) {
          if (isConfirm) {
            baseContext.router.navigateByUrl('/')
          }
        });
      return;
    }
    if (currentIndex == -1)
      currentIndex = 0;
    this.selectedPeriodeId = this.selectedGroupe.session_stage.periodes[currentIndex].periode_id;
  }

  onSelectPeriode($event: any) {
    let selected = this.selectedGroupe.session_stage.periodes.find(x => x.periode_id == $event);
    let today = new Date();
    if (today <= new Date(selected.start_date)) {
      swal({
        title: "Erreur!",
        text: 'Vous ne pouvez pas évaluer cette période.',
        type: "error",
        confirmButtonColor: "#66BB6A",
        confirmButtonText: "D'accord",
        closeOnConfirm: true,
      });
      let currentIndex = this.selectedGroupe.session_stage.periodes.findIndex(p => {
        return today <= new Date(selected.start_date)
      });
      this.selectedPeriodeId = this.selectedGroupe.session_stage.periodes[currentIndex].periode_id;
      return;
    }
    this.selectedPeriode = selected;
    this.getResultGroupesByGroupeAndByPeriode(this.selectedGroupe.groupe_id, $event);
  }

  getResultGroupesByGroupeAndByPeriode(groupe_id: number, $event: any) {
    this.busy = this.stageService.getResultGroupesByGroupeAndByPeriode(groupe_id, $event).subscribe(
      (data) => {
        const number = data.length;
        this.result_groupes = data;
        this.student_aliases = [];
        for (let i = 1; i <= number; i += 1) {
          this.student_aliases.push("Etudiant " + i);
        }
        Utils.initializeDataTables(20, 3);
      }
    );
  }

  evaluer(i: number) {
    this.selectedEtudiantIndex = Number(i);
    this.selectedEtudiant = true;
    this.stageService.context = this;
    this.router.navigateByUrl('/stage/jury/evaluer');
  }
}

class Array_Row {
  label_critere = "";
  ponderation = 0;
  values = [];
  critere_id: number;
  eval_jury: number;
  significations: string[];
}
