import {StorageService} from "app/shared/services/storage.service";
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {Teacher} from "../models/Teacher";
import {environment} from "../../../environments/environment";

@Injectable()
export class UserService extends GenericService {
  loggedUser: Teacher;
  nbr_sessions: number;

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
    this.loggedUser = <Teacher> storageService.read('teacher');
  }

  getLoggedUser() {
    const url = environment.baseUrl + "/teacher/me";

    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getConnectedUser() {
    return <Teacher>this.storageService.read('teacher');
  }

  getTokent() {
    return <string>this.storageService.read("teacher-token")
  }

  generateQRcodeTeacher() {
    const url = environment.baseUrl + "/teacher/me/generateQrCode";

    return this.http.get<any>(url, {
      responseType: 'blob' as 'json'
    })
      .pipe(catchError(this.handleErrors));
  }

  forgotPassword(email: string) {
    const url = environment.baseUrl + "/auth/password/teacher-reset";

    return this.http.post<any>(url, {
      email: email
    })
      .pipe(catchError(this.handleErrors));
  }

  getInformationInscriptionYear(currentUniversityYear: string) {
    const url = environment.baseUrl + "/student/me/registration/information";

    return this.http.post<any>(url, {
      year_university: currentUniversityYear
    })
      .pipe(catchError(this.handleErrors));
  }

  inscrireYearUniversity(levelId: number, id_Registration_University: number) {
    const url = environment.baseUrl + "/student/me/registration";

    return this.http.post<any>(url, {
      level_id: levelId,
      id_Registration_University: id_Registration_University
    })
      .pipe(catchError(this.handleErrors));
  }

  generateDetailPDFStudent() {
    const url = environment.baseUrl + "/student/me/generateDetailPDF";

    return this.http.get<any>(url, {
      responseType: 'blob' as 'json'
    })
      .pipe(catchError(this.handleErrors));
  }

  clear() {
    this.storageService.removeAll();
  }

  inscrireTeacherFormation(id_inscription_formation: number, paymentMedia: string) {
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read("teacher-token"));
    const url = environment.baseUrl + "/teacher/me/registration/formation";

    return this.http.post(url, {
        id_inscription_formation: id_inscription_formation,
        payment_media: paymentMedia
      },
      {
        headers: headers
      })
      .pipe(catchError(this.handleErrors))
  }
}

interface OnConversationOpenObserver {
  onConversationOpenObserver(banqueId: number);
}
