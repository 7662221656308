import {NgModule} from '@angular/core';
import {ListAffectationComponent} from './list-affectation/list-affectation.component';
import {StageModuleRouting} from "./stage.routing";
import {SharedModule} from "../shared/shared.module";
import {EvaluationComponent} from './evaluation/evaluation.component';
import {EvaluationsJuryComponent} from './evaluations-jury/evaluations-jury.component';
import {EvaluerEtudiantComponent} from './evaluer-etudiant/evaluer-etudiant.component';
import {EvaluationEcosComponent} from './evaluation-ecos/evaluation-ecos.component';
import {EvaluationPortfolioComponent} from './evaluation-portfolio/evaluation-portfolio.component';
import {NgNbdocumentsComponent} from "./shared/ng-nbdocuments/ng-nbdocuments.component";
import { NgValidPortfolioComponent } from './shared/ng-valid-portfolio/ng-valid-portfolio.component';

@NgModule({
  imports: [
    StageModuleRouting,
    SharedModule
  ],
  declarations: [
    ListAffectationComponent
    , EvaluationComponent
    , EvaluationsJuryComponent
    , EvaluerEtudiantComponent
    , EvaluationEcosComponent
    , EvaluationPortfolioComponent
    , NgNbdocumentsComponent, NgValidPortfolioComponent]
})
export class StageModule {
}
