/**
 * Created by Abbes on 19/09/2017.
 */
/**
 * Created by Abbes on 04/08/2017.
 */
import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {Observable} from "rxjs/Observable";
import {Country} from "../models/country";
import {City} from "../models/city";
import {environment} from "../../../environments/environment";

@Injectable()
export class SharedService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }


  getAllTypes() {
    const url = environment.baseUrl + "/types";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllMentions() {
    const url = environment.baseUrl + "/mentions";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllResults() {
    const url = environment.baseUrl + "/results";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllUniversities() {
    const url = environment.baseUrl + "/universities";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllLevels() {
    const url = environment.baseUrl + "/levels";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }


  getAllSpecialities() {
    const url = environment.baseUrl + "/specialities";
    const headers = this.headers;
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllGrades() {
    const url = environment.baseUrl + "/grades";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllHopitaux() {
    const url = environment.baseUrl + "/hopitaux";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllServices() {
    const url = environment.baseUrl + "/services";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }


  getAllCredits() {
    const url = environment.baseUrl + "/credits";
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllCountries(): Observable<Country[]> {
    const url = environment.baseUrl + "/geo/countries";
    const countries = this.getAllCountriesFromStorage();

    if (countries) {
      console.log('get countries from locale');
      return Observable.create(observer => {
        observer.next(countries);
        observer.complete();
      })
    } else {
      console.log('get countries from APIs 1 ');
      return this.http.get<any>(url).pipe(map(res => {
        const data = res;
        this.saveAllCountriesFromStorage(data);
        console.log("get countries from APIs 2 ");
        return data;
      }), catchError(this.handleErrors));
    }
  }

  getCitiesByCountry(countryId: string): Observable<City[]> {
    const url = environment.baseUrl + "/geo/countries/" + countryId + "/cities";
    const tunisCities = this.getTunisCitiesFromStorage();
    if (countryId.localeCompare('TUN') === 0 && tunisCities) {
      console.log("getCitiesByCountryFromLocal");
      return Observable.create(observer => {
        observer.next(tunisCities);
        observer.complete();
      })
    } else {
      console.log("getCitiesByCountryFromRemote");
      return this.http.get<any>(url).pipe(map(res => {
        console.log('remote response');
        const data = res;
        if (countryId.localeCompare('TUN') === 0) {
          this.saveTunisCitiesFromStorage(data);
        }
        return data;
      }), catchError(this.handleErrors));
    }
  }

  getAllFonctionTypes() {
    const url = environment.baseUrl + "/fonction_types";
    const headers = this.headers;
    return this.http.get<any>(url)
      .pipe(catchError(this.handleErrors));
  }

  getAllCountriesFromStorage() {
    return <Array<Country>> this.storageService.read('countries');
  }

  saveAllCountriesFromStorage(countries: Array<Country>) {
    this.storageService.write('countries', countries);
  }

  getTunisCitiesFromStorage() {
    return <Array<City>> this.storageService.read('tunis-cities');
  }

  saveTunisCitiesFromStorage(cities: Array<City>) {
    this.storageService.write('tunis-cities', cities);
  }

}
