import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {Bac} from "app/shared/models/bac";
import {Fonction} from "../models/fonction";
import {Doctaurat} from "../models/doctaurat";
import {Residanat} from "../models/residanat";
import {Teacher} from "../models/Teacher";
import {TeacherConcour} from "app/shared/models/Teacher_Concour";
import {environment} from "../../../environments/environment";

@Injectable()
export class TeacherFileService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }


  editInformations(teacher: Teacher) {
    const url = environment.baseUrl + "/teacher/edit";
    return this.http.put<any>(url, teacher)
      .pipe(catchError(this.handleErrors));
  }

  editBacInformation(bac: Bac) {
    const url = environment.baseUrl + "/teacher/me/bac/edit";
    return this.http.put<any>(url, bac)
      .pipe(catchError(this.handleErrors));
  }

  editFonctionInformation(fonctions: Fonction[]) {
    const url = environment.baseUrl + "/teacher/me/fonctions/edit";
    return this.http.put<any>(url, fonctions)
      .pipe(catchError(this.handleErrors));
  }

  editDoctaurat(doctaurat: Doctaurat) {
    const url = environment.baseUrl + "/teacher/me/doctaurat/edit";
    return this.http.put<any>(url, doctaurat)
      .pipe(catchError(this.handleErrors));
  }

  editResidanatInformation(residanat: Residanat) {
    const url = environment.baseUrl + "/teacher/edit-residanat";
    return this.http.put<any>(url, residanat)
      .pipe(catchError(this.handleErrors));
  }

  submitAdmin(sectionIds: number[]) {
    const url = environment.baseUrl + "/teacher/me/submitForReview";
    return this.http.post<any>(url, sectionIds
    )
      .pipe(catchError(this.handleErrors));
  }


  removeDoctaurat() {
    const url = environment.baseUrl + "/teacher/me/doctaurat/remove";
    return this.http.delete<Teacher>(url)
      .pipe(catchError(this.handleErrors));
  }


  editConcours(concour: TeacherConcour) {
    const url = environment.baseUrl + "/teacher/me/concour/edit";
    return this.http.put<any>(url, concour)
      .pipe(catchError(this.handleErrors));
  }

  removeConcour(concourType: number) {
    const url = environment.baseUrl + "/teacher/me/concour/" + concourType + "/remove";
    return this.http.delete<Teacher>(url)
      .pipe(catchError(this.handleErrors));
  }
}
