import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Utils} from "../../utils";

declare let jQuery: any;

@Component({
  selector: 'app-ng-radiobox',
  templateUrl: './ng-radiobox.component.html',
  styleUrls: ['./ng-radiobox.component.css']
})
export class NgRadioboxComponent implements OnInit, AfterViewInit {

  @Input()
  objects: any;

  classe: string;

  selected: any;

  @Output()
  selectionChange: EventEmitter<any>;


  constructor() {
    this.selectionChange = new EventEmitter<any>();
    this.classe = Utils.getRandomString();
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.initCheckBoxes();
  }

  @Input()
  get selection() {
    return this.selected;
  }

  set selection(value) {
    Utils.setRadioBoxValue(this.classe, value);
    this.selected = value;
  }


  private initCheckBoxes() {
    const baseContext = this;
    Utils.initCheckBox(this.classe);
    jQuery('.' + this.classe).on('change', function () {
      baseContext.selected = parseInt(jQuery(this).val());
      baseContext.selectionChange.emit(baseContext.selected);
    });
  }
}
