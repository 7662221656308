/**
 * Created by AHMED on 03/08/2017.
 */
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MenuComponent} from "./menu/menu.component";
import {PostGraduateTrainingComponent} from "./post-graduate-training/post-graduate-training.component";


export const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      {
        path: 'post-graduate-training',
        component: PostGraduateTrainingComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InscriptionsModuleRouting {
}
