/**
 * Created by Abbes on 09/08/2017.
 */
export class Semester {
  public id_Semester: number;
  public start_date: string;
  public end_date: string;
  // public id_Specialite: number;
  public id_university: number;
  public id_Hopital: number;
  public id_Service: number;
  public id_Residanat: number;
}
