import {Component, OnInit} from '@angular/core';
import {StageService} from "../../shared/services/stage.service";
import {Subscription} from "rxjs/Subscription";
import {Utils} from "../../shared/utils";
import {Periode} from "../../shared/models/stage/Periode";
import {Niveau} from "../../shared/models/niveau";
import {Evaluation} from "../../shared/models/stage/Evaluation";
import {PeriodeStage} from "../../shared/models/stage/PeriodeStage";
import {ResultGroupe} from "../../shared/models/stage/ResultGroupe";
import {SessionStage} from "../../shared/models/stage/SessionStage";

declare var swal: any;
declare var jQuery: any;

@Component({
  selector: 'app-list-affectation',
  templateUrl: './list-affectation.component.html',
  styleUrls: ['./list-affectation.component.css']
})
export class ListAffectationComponent implements OnInit {

  evaluations: Evaluation[] = [];
  fixEvaluations: Evaluation[] = [];
  busy: Subscription;

  showSelectPeriod: boolean = false;

  existSessions: SessionStage[] = [];

  fixExistPeriodes: Periode[] = [];
  periodes: Periode[] = [];

  periodesstage: PeriodeStage[] = [];
  fixPeriodeStage: PeriodeStage[] = [];


  constructor(private stageService: StageService) {
  }

  ngOnInit() {
    Utils.initSelect('select-session');
    Utils.initSelect('select-periode');
    Utils.initSelect('select-periode-stage');
    Utils.initSelect('select-week');

    this.getStudentsAffected();
    this.onChangeFilter();
  }

  onChangeFilter() {
    const selectSession = jQuery('.select-session');
    const selectPeriode = jQuery('.select-periode');
    const selectPeriodeStage = jQuery('.select-periode-stage');

    const baseContext = this;

    selectSession.on('change', function () {
      const sessionStageId = parseInt(jQuery(this).val(), 0);
      baseContext.periodes = baseContext.filterPeriodes(sessionStageId);
      const periodeId = Utils.getCurrentPeriodeId(baseContext.periodes);
      console.log("Periode Id :", periodeId);
      Utils.setValuesjQueryCmp('select-periode', periodeId, 5);
      selectPeriode.val(0).trigger('change');
      baseContext.evaluations = baseContext.filterEvaluations(sessionStageId, 0, 0);
      Utils.initializeDataTables(5, 5);
    });

    selectPeriode.on('change', function () {
      const periodeId = parseInt(jQuery(this).val(), 0);
      const sessionId = parseInt(selectSession.val(), 0);
      selectPeriodeStage.val(0).trigger('change');
      if (periodeId) {
        baseContext.periodesstage = baseContext.filterPeriodeStage(periodeId);
        const periodeStageId = Utils.getCurrentPeriodeStageId(periodeId, baseContext.periodesstage);
        console.log("Periode Stage Id : ", periodeStageId);
        Utils.setValuesjQueryCmp('select-periode-stage', periodeStageId, 5);
        baseContext.evaluations = baseContext.filterEvaluations(sessionId, periodeId, 0);
        Utils.initializeDataTables(5, 5);
      }

    });

    selectPeriodeStage.on('change', function () {
      const periodeStageId = parseInt(jQuery(this).val(), 0);
      const periodeId = parseInt(selectPeriode.val(), 0);
      const sessionId = parseInt(selectSession.val(), 0);
      if (periodeStageId) {
        baseContext.evaluations = baseContext.filterEvaluations(sessionId, periodeId, periodeStageId);
        Utils.initializeDataTables(5, 5);
      }
    });
  }

  filterEvaluations(sessionId: number, periodeId: number, periodeStageId: number) {
    let evaluations = this.fixEvaluations;

    if (sessionId) {
      evaluations = evaluations.filter(
        evaluation => {
          if (evaluation.periode_stage.periode.session_stage_id === sessionId) {
            return evaluation;
          }
        }
      )
    }
    if (periodeId) {
      evaluations = evaluations.filter(
        evaluation => {
          if (evaluation.periode_stage.periode.periode_id === periodeId) {
            return evaluation;
          }
        }
      )
    }
    if (periodeStageId) {
      evaluations = evaluations.filter(
        evaluation => {
          if (evaluation.periode_stage.periode_stage_id === periodeStageId) {
            return evaluation;
          }
        }
      )
    }
    return evaluations;
  }

  filterPeriodeStage(periodeId: number) {
    const periodesStage = this.fixPeriodeStage;
    return periodesStage.filter(
      periodeStage => {
        if (periodeStage.periode.periode_id === periodeId) {
          return periodeStage;
        }
      }
    )
  }

  filterPeriodes(sessionStageId: number) {
    const periodes = this.fixExistPeriodes;
    return periodes.filter(
      periode => {
        if (periode.session_stage_id === sessionStageId) {
          return periode;
        }
      }
    )
  }

  getStudentsAffected() {
    const baseContext = this;
    this.busy = this.stageService.getStudentsAffected()
      .subscribe(
        (data: Evaluation[]) => {
          this.evaluations = data;

          this.evaluations.forEach(
            evaluation => {
              if (evaluation.periode_stage.speriodes.length > 0)
                evaluation.enable = new Date() >= Utils.convertRealDate(evaluation.periode_stage.speriodes[0].start_date)
            }
          );


          this.fixEvaluations = data;
          this.existSessions = this.gettingExistingSession(this.fixEvaluations);
          Utils.setValuesjQueryCmp('select-session', this.existSessions[this.existSessions.length - 1].session_stage_id, 5);
          this.fixExistPeriodes = this.gettingExistingPeriode(this.fixEvaluations);

          this.fixPeriodeStage = this.gettingExistingPeriodeStage(this.fixEvaluations);

          console.log(this.fixExistPeriodes);
          // Utils.initializeDataTables(20, 4);
        },
        (error) => {

        }
      )
  }


  private gettingExistingPeriodeStage(fixEvaluations: Evaluation[]) {
    const periodesStage: PeriodeStage[] = fixEvaluations.map(
      evaluation => {
        return evaluation.periode_stage
      }
    );

    return periodesStage.filter((periodeStage, index, self) => {
        return self.findIndex(
          (o) => {
            return o.periode_stage_id === periodeStage.periode_stage_id
          }
        ) === index
      }
    ).sort((a, b) => {
      if (a.periode_stage_id > b.periode_stage_id) return 1;
      else if (a.periode_stage_id < b.periode_stage_id) return -1;
      else return 0;
    });
  }

  private gettingExistingSession(fixEvaluations: Evaluation[]) {

    const sessions: SessionStage[] = fixEvaluations.map(
      evaluation => {
        return evaluation.periode_stage.periode.session_stage
      }
    );

    return sessions.filter((session, index, self) => {
        return self.findIndex(
          (o) => {
            return o.session_stage_id === session.session_stage_id
          }
        ) === index
      }
    );
  }

  private gettingExistingPeriode(fixEvaluations: Evaluation[]) {
    const periodes = fixEvaluations.map(
      evaluation => {
        return evaluation.periode_stage.periode;
      }
    );
    return periodes.filter((periode, index, self) => {
      return self.findIndex(
        (o) => {
          return o.periode_id === periode.periode_id
        }
      ) === index
    }).sort((a, b) => {
      if (a.periode_id > b.periode_id) return 1;
      else if (a.periode_id < b.periode_id) return -1;
      else return 0;
    });
  }

  addNoteEcos(studentId: number, periodeId: number, index: number) {

    if (this.evaluations[index].note_ecos > 0 && this.evaluations[index].note_ecos <= 20) {
      this.busy = this.stageService.addEcosNotePerPeriode(studentId, periodeId, this.evaluations[index].note_ecos)
        .subscribe(
          (data: ResultGroupe) => {
            const indexResultGroup = this.evaluations[index].etudiant.group_results
              .map(
                el => {
                  return el.result_groupe_id
                }
              ).indexOf(data.result_groupe_id);
            this.evaluations[index].note_ecos = 0;
            this.evaluations[index].etudiant.group_results[indexResultGroup].note_ecos = data.note_ecos;
          },
          (error) => {

          }
        )
    } else {
      swal('Warning', 'Note entre 0 et 20', 'warning');
    }
  }

  ifAllowed(periode: Periode) {

    const endDate = Utils.convertRealDateServer(periode.end_date);

    if (Utils.dateBetween(new Date(endDate.getTime() - (7 * 24 * 60 * 60 * 1000)),
      new Date(endDate.getTime() + (7 * 24 * 60 * 60 * 1000))
    )) {
      return true;
    }
  }
}
