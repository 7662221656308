import {Etudiant} from "../Etudiant";
import {S_Periode} from "./S_Periode";
import {Service} from "../service";
import {PeriodeStage} from "./PeriodeStage";
import {Evaluation_Critere} from "./Evaluation_Critere";
import {EvaluationCClinique} from "./EvaluationCClinique";
import {EvaluationPortfolio} from "./EvaluationPortfolio";

export class Evaluation {
  public evaluation_id: number;
  public note: number = null;
  public remarque: string;
  public s_periode_id: number;
  public etudiant_id: number;

  public service: Service = new Service();
  public etudiant: Etudiant = new Etudiant();
  public speriode: S_Periode = new S_Periode();

  // extra
  enable: boolean = false;

  public periode_stage: PeriodeStage = new PeriodeStage();

  public note_ecos: number = 0;
  public evaluations_criteres: Evaluation_Critere[] = [];

  public ecos_evaluation: EvaluationCClinique;
  public evaluation_portfolio: EvaluationPortfolio;

}
