/**
 * Created by Abbes on 18/07/2017.
 */
/**
 * Created by Abbes on 13/07/2017.
 */
import {Component, Input, OnInit, ViewContainerRef} from "@angular/core";
import {Router} from "@angular/router";
declare var jQuery;

@Component({
  selector: "app-header-navigation",
  templateUrl: 'header-navigation.html',
  styleUrls: []
})
export class HeaderNavigationComponent implements OnInit {


  @Input()
  items: NavigationItem[];

  constructor(private router: Router) {


  }

  ngOnInit(): void {

  }

  goPage(url: string) {
    this.router.navigate([url]);
  }


}
export class NavigationItem {
  public title: string;
  public url: string;
  public active: boolean
}
