import {FormationNiveau} from "./formation_niveau";
import {TypeFormation} from "./type_formation";

export class SessionFormation {
  id_session_formation: number;
  label: string;
  year_university: string;
  start_date_choice: string;
  end_date_choice: string;
  affectation: boolean;
}
