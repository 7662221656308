import {SectionValidation} from "./models/section-validation";
import {PartielNotificationDetection} from "./models/PartielNotificationDetection";
import {Student} from "./models/student";
import {Periode} from "./models/stage/Periode";
import {PeriodeStage} from "./models/stage/PeriodeStage";
import {S_Periode} from "./models/stage/S_Periode";
import {Formation} from "./models/formation";
import {ItemSessionFormation} from "./models/item-session-formation";

/**
 * Created by Abbes on 30/06/2017.
 */
declare var jQuery: any;

/**
 * Created by Vyndee on 27/03/2017.
 */
export class Utils {


  public static zero(n: number) {
    if (n < 10) {
      return "0" + n;
    }
    return n;
  }

  static configDataTables() {
    /* jQuery.extend(jQuery.fn.dataTable.defaults, {
     autoWidth: false,
     columnDefs: [{
     orderable: false,
     width: '100px',
     targets: [5]
     }],
     dom: '<"datatable-header"fl><"datatable-scroll"t><"datatable-footer"ip>',
     language: {
     search: '<span>Filter:</span> _INPUT_',
     lengthMenu: '<span>Show:</span> _MENU_',
     paginate: {'first': 'First', 'last': 'Last', 'next': '&rarr;', 'previous': '&larr;'}
     },
     drawCallback: function () {
     jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').addClass('dropup');
     },
     preDrawCallback: function () {
     jQuery(this).find('tbody tr').slice(-3).find('.dropdown, .btn-group').removeClass('dropup');
     }
     });*/
  }


  static getStatusSection(validations: SectionValidation[], id_section: number) {

    if (!validations) {
      return null;
    }
    const result = jQuery.grep(validations, function (e) {
      return e.id_section === id_section;
    });


    if (!result || result.length === 0) {
      return null;
    } else {
      return result[0];
    }
  }

  private static groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  static initSelect(className: string) {
    const select = jQuery('.' + className);
    select.select2();
  }

  static setValuesjQueryCmp(className: string, values: any, timout: number) {

    if (values) {
      setTimeout(function () {
        const component = jQuery('.' + className);
        component.val(values).trigger('change');
      }, timout);

    }
  }

  static initializeDataTables(timout: number, columnNumber: number, title?: string, orderBy ?: number) {
    const tableListStation = jQuery('.datatable-basic');
    if (jQuery.fn.DataTable.isDataTable('.datatable-basic')) {
      tableListStation.dataTable().fnDestroy();
    }
    console.log("order By " + orderBy);

    setTimeout(function () {
      tableListStation.DataTable({
        "bDestroy": true,
        dom: 'Bfrtip',
        language: {
          "emptyTable": "..."
        },
        buttons: {
          dom: {
            button: {
              className: 'btn btn-default'
            }
          },
          buttons: [
            'excelHtml5',
            'pdfHtml5'
          ]
        }
        ,
        columnDefs: [{
          targets: [columnNumber - 1]
        }]
      });
    }, timout);
  }

  static convertDate(date: string) {
    if (date) {
      return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4);
    }
    return null;
  }

  static convertTime(time: string) {
    if (time) {
      return time.substring(0, 5);
    }
    return null;
  }

  static convertRealDate(date: string, time?: string) {
    if (date) {
      if (!time) {
        return new Date(+date.substring(0, 4), (+date.substring(5, 7) - 1), +date.substring(8, 10));
      } else {
        return new Date(+date.substring(0, 4), (+date.substring(5, 7) - 1), +date.substring(8, 10)
          , +time.substring(0, 2), +time.substring(3, 5));
      }
    }
    return null;
  }

  static getModalTemplate() {
    return '<div class="modal-dialog modal-lg" role="document">\n' +
      '  <div class="modal-content">\n' +
      '    <div class="modal-header">\n' +
      '      <div class="kv-zoom-actions btn-group">{toggleheader}{fullscreen}{borderless}{close}</div>\n' +
      '      <h6 class="modal-title">{heading} <small><span class="kv-zoom-title"></span></small></h6>\n' +
      '    </div>\n' +
      '    <div class="modal-body">\n' +
      '      <div class="floating-buttons btn-group"></div>\n' +
      '      <div class="kv-zoom-body file-zoom-content"></div>\n' + '{prev} {next}\n' +
      '    </div>\n' +
      '  </div>\n' +
      '</div>\n';
  }

  static getPreviewZoomButtonClasses() {
    return {
      toggleheader: 'btn btn-default btn-icon btn-xs btn-header-toggle',
      fullscreen: 'btn btn-default btn-icon btn-xs',
      borderless: 'btn btn-default btn-icon btn-xs',
      close: 'btn btn-default btn-icon btn-xs'
    };
  }

  static getPreviewZoomButtonIcons() {
    return {
      prev: '<i class="icon-arrow-left32"></i>',
      next: '<i class="icon-arrow-right32"></i>',
      toggleheader: '<i class="icon-menu-open"></i>',
      fullscreen: '<i class="icon-screen-full"></i>',
      borderless: '<i class="icon-alignment-unalign"></i>',
      close: '<i class="icon-cross3"></i>'
    };
  }

  static initializeUploadFile(url: string, token: string, className: string, maxFileCount?: number, initialData?: any[],
                              initialPreviewConfig?: InitialPreviewConfig[], allowedFileExtensions?: string[]) {
    jQuery(className).fileinput({
      uploadUrl: url, // server upload action
      uploadAsync: true,
      maxFileCount: maxFileCount,
      allowedFileExtensions: allowedFileExtensions,
      showUpload: false,
      showRemove: false,
      initialPreview: initialData,
      overwriteInitial: false,
      initialPreviewConfig: initialPreviewConfig,
      fileActionSettings: {
        removeIcon: '<i class="icon-bin"></i>',
        removeClass: 'btn btn-link btn-xs btn-icon',
        uploadIcon: '<i class="icon-upload"></i>',
        uploadClass: 'btn btn-link btn-xs btn-icon',
        zoomIcon: '<i class="icon-zoomin3"></i>',
        zoomClass: 'btn btn-link btn-xs btn-icon',
        downloadIcon: '<i class="icon-download"></i>',
        downloadClass: 'btn btn-link btn-xs btn-icon',
        indicatorNew: '<i class="icon-file-plus text-slate"></i>',
        indicatorSuccess: '<i class="icon-checkmark3 file-icon-large text-success"></i>',
        indicatorError: '<i class="icon-cross2 text-danger"></i>',
        indicatorLoading: '<i class="icon-spinner2 spinner text-muted"></i>',
      },
      purifyHtml: true, // this by default purifies HTML data for preview
      initialPreviewAsData: true,
      initialCaption: "Pas encore de fichier selectionné",
      previewZoomButtonClasses: Utils.getPreviewZoomButtonClasses(),
      previewZoomButtonIcons: Utils.getPreviewZoomButtonIcons(),
      ajaxSettings: {headers: {'Authorization': 'Bearer ' + token}},
    }).on("filebatchselected", function (event, files) {
      jQuery(className).fileinput("upload");
    });


  }

  static convertDateServer(date: string) {
    return date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2);
  }

  static loadTypeFromExtension(ext: string) {
    if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
      return "video";
    }
    if (ext.toLowerCase().match(/(pdf)$/i)) {
      return "pdf";
    }
  }

  static loadFileTypeFromExtension(ext: string) {
    if (ext.toLowerCase().match(/(avi|mpg|mkv|mov|mp4|3gp|webm|wmv)$/i)) {
      return "video/" + ext;
    }
    if (ext.toLowerCase().match(/(pdf)$/i)) {
      return "pdf";
    }
  }

  static getYears(minYear: number) {
    const years: number[] = [];

    for (let i = minYear; i <= new Date().getFullYear(); i++) {
      years.push(i);
    }
    return years;
  }

  static getUniversityYears(minYear: number) {
    const uniYears: string[] = [];

    for (let i = minYear; i <= new Date().getFullYear(); i++) {
      const j = i + 1;
      uniYears.push(i + "-" + j);
    }

    return uniYears;
  }

  static verifyNewStudent(study_access_year: string) {
    if (!study_access_year) {
      return false;
    }
    const year = parseInt(study_access_year.substring(0, 4));
    return year === new Date().getFullYear();
  }

  static getCurrentUniversityYear(cycle: number) {
    // cycle => 1 : PDCEM , 2 : IRESIDANT
    let fullYear = new Date().getFullYear();
    if (cycle === 1) {
      if (new Date().getMonth() >= 0 && new Date().getMonth() <= 4) {
        fullYear -= 1;
      }
    } else {
      if (new Date().getMonth() >= 0 && new Date().getMonth() <= 11) {
        fullYear -= 1;
      }
    }
    return (fullYear) + "-" + (fullYear + 1)
  }

  static convertRealDateServer(date: string) {
    if (date) {
      const HH = +date.substring(11, 13);
      const mm = +date.substring(14, 16);

      return new Date(+date.substring(0, 4), (+date.substring(5, 7) - 1), +date.substring(8, 10), HH, mm);
    }
    return null;
  }

  static getPartielNotification(partielId: number, data: PartielNotificationDetection[]) {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].sub_section.section.id_Partiel_Notification === partielId) {
        sum++;
      }
    }
    return sum;
  }

  static getNotificationBySection(sectionId: number, data: PartielNotificationDetection[]) {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].sub_section.id_Partiel_Notification_Section === sectionId) {
        sum++;
      }
    }
    return sum;
  }

  static getNotificationBySubSection(subSectionId: number, data: PartielNotificationDetection[]) {
    let sum = 0;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id_Partiel_Notification_Sub_Section === subSectionId) {
        sum++;
      }
    }
    return sum;
  }

  static getLabelNiveau(niveau_id: number) {
    switch (niveau_id) {
      case 1 :
        return "PCEM1";
      case 2 :
        return "PCEM2";
      case 3 :
        return "DCEM1";
      case 4 :
        return "DCEM2";
      case 5 :
        return "DCEM3";
      case 6 :
        return "DCEM4";
      case 7 :
        return "DCEM4 (AR)";
      case 8:
        return "TCEM1";
      case 9:
        return "TCEM2";
      case 10:
        return "TCEM3";
      case 11:
        return "TCEM4";
      case 12:
        return "TCEM5";

    }
  }

  static dateBetween(startDate: Date, endDate: Date) {
    return new Date() >= startDate && new Date() <= endDate;
  }

  static initializeBasicUploadFile(url: string, token: string, className: string) {
    jQuery('.' + className).fileinput({
      uploadUrl: url, // server upload action
      browseLabel: 'Browse',
      browseIcon: '<i class="icon-file-plus"></i>',
      uploadIcon: '<i class="icon-file-upload2"></i>',
      removeIcon: '<i class="icon-cross3"></i>',
      initialCaption: "No file selected",
      ajaxSettings: {headers: {'Authorization': 'Bearer ' + token}},
    });
  }

  static getMailsByListStudents(students: Student[]) {
    return students.map(
      student => {
        return student.email;
      }
    );
  }

  static getCurrentPeriodeId(periodes: Periode[]) {

    for (let i = 0; i < periodes.length; i++) {
      const startDate = Utils.convertRealDate(periodes[i].start_date);
      const endDate = Utils.convertRealDate(periodes[i].end_date);
      if (startDate <= new Date() && endDate >= new Date()) {
        return periodes[i].periode_id;
      }
    }
    return periodes[0].periode_id;
  }

  static getCurrentPeriodeStageId(periodeId: number, fixPeriodeStage: PeriodeStage[]) {

    const periodeStageByPeriodeId = fixPeriodeStage.filter(
      periodeStage => {
        if (periodeId === periodeStage.periode.periode_id) {
          return periodeStage;
        }
      }
    );

    for (let i = 0; i < periodeStageByPeriodeId.length; i++) {
      if (Utils.dateBetweenSperiodes(new Date(), periodeStageByPeriodeId[i].speriodes)) {
        return periodeStageByPeriodeId[i].periode_stage_id;
      }
    }

    return periodeStageByPeriodeId[0].periode_stage_id;
  }

  private static dateBetweenSperiodes(date: Date, speriodes: S_Periode[]) {

    for (let i = 1; i < speriodes.length; i++) {
      const startDate = Utils.convertRealDate(speriodes[i].start_date);
      const endDate = Utils.convertRealDate(speriodes[i].end_date);
      if (startDate <= date && endDate >= date) {
        return true;
      }
      const endDatePast = Utils.convertRealDate(speriodes[i - 1].end_date);
      if (endDatePast <= date && endDate >= date) {
        return true;
      }
    }
    return Utils.convertRealDate(speriodes[0].start_date) <= date &&
      Utils.convertRealDate(speriodes[0].end_date) >= date

  }

  static initDatePicker(className: string, timePicker: boolean) {
    jQuery('.' + className).daterangepicker({
      timePicker: timePicker,
      timePicker24Hour: true,
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: 'DD/MM/YYYY HH:mm'
      }
    });

  }

  static convertToFormation(itemsSession: ItemSessionFormation[]) {

    let formations: Formation[] = [];

    itemsSession.forEach(
      item => {
        let formation = item.formation;
        formation['capacite'] = item.nb_places;
        formation['id_item_session_formation'] = item.id_item_session_formation;
        formations.push(formation);
      }
    );
    return formations;
  }

  static getRandomString() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  static getRandomStringArray(size: number) {
    let res = [];
    for (let i = 0; i < size; i++) {
      res.push(Utils.getRandomString());
    }
    return res;
  }

  static initCheckBox(classe: string) {
    const checkBox = jQuery('.' + classe);

    checkBox.uniform({
      radioClass: 'choice'
    });
  }

  static setRadioBoxValue(className: any, value: any) {
    setTimeout(function () {
      jQuery('.' + className + '[value=' + value + ']').prop('checked', true);
      jQuery.uniform.update('.' + className + '[value=' + value + ']');
    }, 5);
  }
}


export class InitialPreviewConfig {
  caption?: string;
  size?: number;
  width?: string;
  type?: string;
  filetype?: string;
  url: string;
  key: number;
  downloadUrl?: string;
}

