import {Component, OnInit} from '@angular/core';
import {SessionFormation} from "../../shared/models/session_formation";
import {PostGraduateTrainingService} from "../../shared/services/post-graduate-training.service";
import {Utils} from "../../shared/utils";
import {UserService} from "../../shared/services/user.service";
import {Subscription} from "rxjs";
import {Formation} from "../../shared/models/formation";
import {ItemSessionFormation} from "../../shared/models/item-session-formation";

@Component({
  selector: 'app-manage-formations',
  templateUrl: './manage-formations.component.html',
  styleUrls: ['./manage-formations.component.css']
})
export class ManageFormationsComponent implements OnInit {

  sessionFormation: SessionFormation;
  busy: Subscription;
  formations: Formation[] = [];


  constructor(private postTrainingServices: PostGraduateTrainingService,
              private userServices: UserService) {
    this.sessionFormation = new SessionFormation();
  }

  ngOnInit() {

    this.postTrainingServices.getSessionFormationByYearUniversity(Utils.getCurrentUniversityYear(1))
      .subscribe(
        (data: SessionFormation) => {
          this.sessionFormation = data;
          this.getAllFormationByTeacher(this.sessionFormation.id_session_formation);
        },
        (error) => {

        }
      )
  }

  private getAllFormationByTeacher(sessionFormationId: number) {
    this.busy = this.postTrainingServices.getAllFormationByCoordinator(sessionFormationId)
      .subscribe(
        (data: ItemSessionFormation[]) => {
          this.formations = Utils.convertToFormation(data);
        },
        (error) => {

        }
      )
  }
}
