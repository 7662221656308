import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from "@angular/common/http";
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {UserService} from "./user.service";
import {Formation} from "../models/formation";
import {MailStructure} from "../../attributions/list-validated-inscriptions/list-validated-inscriptions.component";
import {environment} from "../../../environments/environment";


@Injectable()
export class PostGraduateTrainingService extends GenericService {

  constructor(private http: HttpClient, private storageService: StorageService,
              private userService: UserService) {
    super();
  }

  getAllFormationByTeacher(sessionFormationId) {
    const url = environment.baseUrl + '/teacher/session-formation/' + sessionFormationId + '/formation/all';
    return this.http.get(url).pipe(
      catchError(this.handleErrors)
    )
  }

  addCandidateChoice(formations: Formation[]) {
    const url = environment.baseUrl + '/teacher/formation/choice';
    return this.http.post(url, formations).pipe(
      catchError(this.handleErrors)
    )
  }

  getChoicesByCandidat(sessionFormationId) {
    const url = environment.baseUrl + '/teacher/session-formation/' + sessionFormationId + '/formation/choice';
    return this.http.get(url).pipe(
      catchError(this.handleErrors)
    )
  }

  getFormationById(formationId: number) {
    const url = environment.baseUrl + '/teacher/formation/' + formationId;
    return this.http.get(url).pipe(
      catchError(this.handleErrors)
    )
  }

  getInscriptionsByFormation(sessionFormationId: number, formationId: number) {
    const url = environment.baseUrl + '/teacher/session-formation/' + sessionFormationId + '/formation/' + formationId + '/inscriptions';
    return this.http.get(url).pipe(
      catchError(this.handleErrors)
    )
  }

  changeDecisionCoordinateur(formationId: number, preInscriptionId: number, status: number) {
    const url = environment.baseUrl + '/teacher/formation/' + formationId + '/preinscription/' + preInscriptionId + '/change-status';
    return this.http.post(url, {
        status: status
      }
    ).pipe(
      catchError(this.handleErrors)
    )
  }

  getSessionFormationByYearUniversity(year_university: string) {
    const url = environment.baseUrl + '/student/session-formation/year-university';
    return this.http.post(url, {
        year_university: year_university
      }
    ).pipe(catchError

      (
        this
          .handleErrors
      )
    )
  }

  getAllInscriptionsByTeacher(sessionFormationId) {
    const url = environment.baseUrl + '/teacher/session-formation/' + sessionFormationId + '/formation/inscriptions';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getValidatedInscriptionsByFormation(sessionFormationId: number, formationId: number) {

    const url = environment.baseUrl + '/teacher/session-formation/' + sessionFormationId + '/formation/' + formationId + '/validatedInscriptions';
    const headers = this.headers.set("Authorization", "Bearer " + this.storageService.read('admin-token'));

    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    );
  }

  getPreInscriptionByTeacher() {
    const url = environment.baseUrl + '/teacher/formation/preinscriptions';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  sendMailByStudents(mail: MailStructure, mails: string[]) {
    const url = environment.baseUrl + '/teacher/me/send-mail-students';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.post(url, {
      mail: mail,
      mails: mails
    }, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }

  getAllFormationByCoordinator(sessionFormationId: number) {
    const url = environment.baseUrl + '/teacher/session-formation/' + sessionFormationId + '/formations';
    const headers = this.headers.set("Authorization", "Bearer " + this.userService.getTokent());
    return this.http.get(url, {
      headers: headers
    }).pipe(
      catchError(this.handleErrors)
    )
  }
}
