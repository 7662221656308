import {Component, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {Evaluation} from "../../shared/models/stage/Evaluation";
import {StageService} from "../../shared/services/stage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {GCompetanceClinique} from "../../shared/models/stage/GCompetanceClinique";

declare let jQuery: any;
declare let swal: any;

@Component({
  selector: 'app-evaluation-ecos',
  templateUrl: './evaluation-ecos.component.html',
  styleUrls: ['./evaluation-ecos.component.css']
})
export class EvaluationEcosComponent implements OnInit {

  busy: Subscription;

  evaluationId: number;
  evaluation: Evaluation = new Evaluation();
  baseUrl: string = environment.baseUrl;
  gcompetances: GCompetanceClinique[] = [];


  constructor(private stageService: StageService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit() {
    this.evaluationId = parseInt(this.route.snapshot.paramMap.get('evaluationId'));

    this.getAllGCompetances();
    this.getEvaluation();


  }

  getEvaluation() {
    this.busy = this.stageService.getEvaluationById(this.evaluationId)
      .subscribe(
        (data) => {
          this.evaluation = data;
        },
        (error) => {

        }
      )
  }

  getAllGCompetances() {
    this.busy = this.stageService.getAllGCompetances()
      .subscribe(
        (data) => {
          this.gcompetances = data;
          this.initCheckBox();
          this.initRadioBox();
        },
        (error) => {
        }
      )
  }

  initRadioBox() {
    const baseContext = this;
    for (let i = 0; i < this.gcompetances.length; i++) {
      let gcompetance = this.gcompetances[i];
      for (let j = 0; j < gcompetance.competances.length; j++) {
        gcompetance.competances[j].value = 1;
        setTimeout(function () {
          baseContext.initItemRadioBox(i, j);
        })
      }
    }
  }

  initCheckBox() {
    const baseContext = this;
    for (let i = 0; i < this.gcompetances.length; i++) {
      let gcompetance = this.gcompetances[i];
      for (let j = 0; j < gcompetance.competances.length; j++) {
        gcompetance.competances[j].na = false;
        setTimeout(function () {
          baseContext.initItemCheckbox(i, j);
        })
      }
    }
  }

  initItemCheckbox(indexG, index: number) {
    const baseContext = this;
    const checkBox = jQuery('.checkbox-inline-na-' + indexG + '-' + index);

    checkBox.uniform({
      radioClass: 'choice'
    });
    checkBox.on("change", function () {
      baseContext.gcompetances[indexG].competances[index].na = !baseContext.gcompetances[indexG].competances[index].na;
    });
  }

  initItemRadioBox(indexG: number, index: number) {
    const baseContext = this;
    const radioBox = jQuery('.radioBox-critere-' + indexG + '-' + index);

    radioBox.uniform({
      radioClass: 'choice'
    });

    radioBox.on("change", function () {
      baseContext.gcompetances[indexG].competances[index].value = parseInt(jQuery(this).val());
    });
  }

  submitEvaluation() {
    const baseContext = this;
    swal({
      title: 'Vous etes sur?',
      text: "Confirmation d'evaluation de ce stage!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Oui'
    }, function (isConfirm) {
      if (isConfirm) {
        baseContext.busy = baseContext.stageService
          .evaluateEcos(baseContext.evaluationId, baseContext.evaluation.etudiant_id, baseContext.gcompetances)
          .subscribe(
            (data) => {
              swal("Succées", "Evaluation faite avec succées", "success");
              baseContext.router.navigate(['/stage/list-affectation']);
            }
          )

      }
    })
  }


}
