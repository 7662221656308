import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {ListAffectationComponent} from "./list-affectation/list-affectation.component";
import {EvaluationComponent} from "./evaluation/evaluation.component";
import {EvaluationsJuryComponent} from "./evaluations-jury/evaluations-jury.component";
import {EvaluerEtudiantComponent} from "./evaluer-etudiant/evaluer-etudiant.component";
import {EvaluationEcosComponent} from "./evaluation-ecos/evaluation-ecos.component";
import {EvaluationPortfolioComponent} from "./evaluation-portfolio/evaluation-portfolio.component";

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-affectation',
        component: ListAffectationComponent
      },
      {
        path: 'jury',
        component: EvaluationsJuryComponent
      },
      {
        path: 'jury/evaluer',
        component: EvaluerEtudiantComponent
      },
      {
        path: ':evaluationId',
        children: [
          {
            path: 'evaluation-ecos',
            component: EvaluationEcosComponent
          },
          {
            path: 'evaluation',
            component: EvaluationComponent
          },
          {
            path: 'evaluation-portfolio',
            component: EvaluationPortfolioComponent
          }

        ]

      }


    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StageModuleRouting {
}
