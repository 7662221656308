import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs/Subscription";
import {UserService} from "../../shared/services/user.service";
import {ConversationService} from "../../shared/services/conversation.service";
import {Router} from "@angular/router";
import {Teacher} from "../../shared/models/Teacher";
import {environment} from "../../../environments/environment";

declare let swal: any;
declare let jQuery;

@Component({
  templateUrl: 'boite-reclamation.component.html',
  styleUrls: [],

})
export class BoiteReclamationComponent implements OnInit {
  loggedUser: Teacher = new Teacher();

  baseURL: string;
  busy: Subscription;
  reclamtionContent: string;
  topicToSend: string;

  ngOnInit() {
    this.loggedUser = this.userService.loggedUser;
  }

  constructor(private userService: UserService, private router: Router,
              private conversationservice: ConversationService) {
    this.baseURL = environment.baseUrl;
  }

  sendReclamation() {
    const baseContext = this;
    /*
     this.studentService.sendMail(baseContext.selectedStudent.id_student, baseContext.emailToSend).subscribe(data => {

     });
     */
    jQuery("#modal_form_vertical").modal("hide");
    this.busy = this.conversationservice.startConversation(this.userService.loggedUser.id_Teacher,
      null, baseContext.reclamtionContent, baseContext.topicToSend).subscribe(data => {
      swal({
          title: "Succés!",
          text: 'Message envoyé avec succès, voulez-vous ouvrir le conversation ?',
          type: "success",
          showCancelButton: true,
          confirmButtonColor: "#66BB6A",
          confirmButtonText: "Oui, ouvrir!",
          cancelButtonText: "Non, merci!",
          closeOnConfirm: true,
          closeOnCancel: true
        },
        function (isConfirm) {
          if (isConfirm) {
            baseContext.router.navigateByUrl('/support/messages/' + data.conversation.id_Conversation + '/discussion')
          }
        });
    });
    this.reclamtionContent = '';
    this.topicToSend = '';
  }

}
