import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
// Layouts
import {FullLayoutComponent} from "./layouts/full-layout.component";
import {LoginComponent} from "./login/login.component";
import {NotFoundComponent} from "./error/not-found.component";
import {TeacherRegistrationComponent} from "./registration/teacher-registration.component";
import {ManageTeacherFileModule} from "./teacher-file/teacher-file.module"
import {SupportModule} from "./support/support.module"
import {StageModule} from "./stage/stage.module"
import {ErrorModule} from "./error/error.module"
import {AttributionsModule} from "./attributions/attributions.module";
import {InscriptionsModule} from "./inscriptions/inscriptions.module";
import {PortfolioModule} from "./portfolio/portfolio.module";

export function loadManageTeacherFileModule() {
  return ManageTeacherFileModule
}

export function loadSupportModule() {
  return SupportModule
}

export function loadStageModule() {
  return StageModule
}

export function loadErrorModule() {
  return ErrorModule
}

export function loadAttributionsModule() {
  return AttributionsModule
}

export function loadInscriptionsModule() {
  return InscriptionsModule
}

export function loadPortfolioModule() {
  return PortfolioModule
}

export const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: loadManageTeacherFileModule
      },
      {
        path: 'teacher-file',
        loadChildren: loadManageTeacherFileModule
      },
      {
        path: 'support',
        loadChildren: loadSupportModule
      },
      {
        path: 'stage',
        loadChildren: loadStageModule
      }/*,
             {
             path: 'inscription',
             loadChildren: "./inscriptions/inscriptions.module#InscriptionsModule"
             },*/,
      {
        path: 'error',
        loadChildren: loadErrorModule
      },
      {
        path: 'attributions',
        loadChildren: loadAttributionsModule
      },
      {
        path: 'inscriptions',
        loadChildren: loadInscriptionsModule
      },
      {
        path: 'portfolio',
        loadChildren: loadPortfolioModule
      },
      {
        path: 'error',
        loadChildren: loadErrorModule
      }
    ]

  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'teacher-registration',
    component: TeacherRegistrationComponent
  },

  {path: '**', component: NotFoundComponent}
  ,
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
