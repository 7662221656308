/**
 * Created by Abbes on 04/08/2017.
 */
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {SharedModule} from "../shared/shared.module";
import {ConstructionComponent} from "./Construction/contruction.component";
import {ErrorModuleRouting} from "./error.routing";


@NgModule({
  imports: [
    ErrorModuleRouting,
    SharedModule
  ],
  declarations: [
    ConstructionComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: []
})
export class ErrorModule {
}
