import {NgModule} from "@angular/core";
import {NgBusyModule} from "ng-busy";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {StorageService} from "./services/storage.service";
import {HeaderNavigationComponent} from "./UI/header-navigation/header-navigation";
import {MomentModule} from "ngx-moment";
import {TeacherRegistrationComponent} from "../registration/teacher-registration.component";
import {ValidationNoteComponent} from "../teacher-file/shared/validation-note.component";
import {NgSelect2Component} from "./components/ng-select2/ng-select2.component";
import {NgDatepickerComponent} from "./components/ng-datepicker/ng-datepicker.component";
import {NgDropdownCustomComponent} from "./components/ng-dropdown-custom/ng-dropdown-custom.component";
import {NgRadioboxComponent} from './components/ng-radiobox/ng-radiobox.component';
import {NgEmogiComponent} from './components/ng-emogi/ng-emogi.component';
import {RoundPipe} from "./pipes/round.pipe";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgBusyModule,
    MomentModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    NgBusyModule,
    HeaderNavigationComponent,
    ValidationNoteComponent,
    NgSelect2Component,
    NgDatepickerComponent,
    NgDropdownCustomComponent,
    MomentModule,
    NgRadioboxComponent,
    NgEmogiComponent,
    RoundPipe
  ],
  declarations: [
    NgDatepickerComponent,
    NgSelect2Component,
    HeaderNavigationComponent,
    NgDropdownCustomComponent,
    TeacherRegistrationComponent,
    ValidationNoteComponent,
    NgRadioboxComponent,
    NgEmogiComponent,
    RoundPipe
  ],
  providers: [StorageService]
})
export class SharedModule {

}
