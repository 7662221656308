import {Niveau} from "./niveau";
import {ResultGroupe} from "./stage/ResultGroupe";

export class Etudiant {
  public etudiant_id: number;
  public nom: string;
  public prenom: string;
  public CIN: string;
  public carte_Etudiant: string;
  public email: string;
  public qr_code: string;
  public niveau_id: number;

  public classement: number;
  public profile_img: string;
  public niveau: Niveau = new Niveau();
  public group_results: ResultGroupe[] = []
}
