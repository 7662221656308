import {AfterViewInit, Component, DoCheck, EventEmitter, Input, IterableDiffers, OnInit, Output} from '@angular/core';
import {Utils} from '../../utils';

declare var jQuery: any;

@Component({
  selector: 'app-ng-dropdown-custom',
  templateUrl: './ng-dropdown-custom.component.html',
  styleUrls: ['./ng-dropdown-custom.component.css']
})
export class NgDropdownCustomComponent implements OnInit, DoCheck, AfterViewInit {

  @Input()
  title: string;

  @Input()
  objects: any;


  @Input()
  stringOption: string;

  @Input()
  idOption: string;

  @Input()
  iconOption?: string;


  className: string;

  options: string[] = [];
  ids: any[] = [];
  icons: string[] = [];

  iterableDiffer: any;

  selected: number[] = [];

  @Output()
  selectionChange: EventEmitter<any>;

  constructor(private _iterableDiffers: IterableDiffers) {
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    this.selectionChange = new EventEmitter<any>();
    this.className = Utils.getRandomString();
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    const baseContext = this;
    // Utils.initCheckBox(baseContext.className);

    jQuery('.' + baseContext.className).on('change', function () {
      baseContext.changingSelection(parseInt(jQuery(this).val()));
      baseContext.selectionChange.emit(baseContext.selected);
      console.log(jQuery(this).val());

      // baseContext.selected = jQuery(this).val();
      // baseContext.selectionChange.emit(baseContext.selected);
    });

  }

  changingSelection(value: number) {
    const index = this.selected.indexOf(value);
    if (index !== -1) {
      this.selected.splice(index, 1);
    } else {
      this.selected.push(value);
    }
  }

  ngDoCheck(): void {
    let changes = this.iterableDiffer.diff(this.objects);
    if (changes) {
      this.transformData();
    }
  }

  @Input()
  get selection() {
    return this.selected;
  }

  set selection(value) {
    // Utils.setValuesjQueryCmp(this.className, value, 50);
    this.selected = value;
  }

  transformData() {
    const baseContext = this;
    if (baseContext.stringOption && baseContext.idOption) {
      this.options = this.objects
        .map(object => {
          return object[baseContext.stringOption];
        });
      this.ids = this.objects
        .map(object => {
          return object[baseContext.idOption];
        });
    } else {
      this.options = this.objects;
      this.ids = this.objects;
    }
    if (baseContext.iconOption) {
      this.icons = this.objects
        .map(object => {
          return object[baseContext.iconOption];
        });
    }
  }

}
