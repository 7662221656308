import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {StorageService} from "../shared/services/storage.service";
import {Credentials} from "../shared/models/credentials";
import {AuthService} from "../shared/services/auth.service";
import {Subscription} from "rxjs/Subscription";
import {UserService} from "../shared/services/user.service";
import {ConversationService} from "../shared/services/conversation.service";

declare let jQuery: any;
declare let swal: any;

@Component({
  templateUrl: './login.component.html',
  styleUrls: [],

})
export class LoginComponent implements OnInit {


  isLoading: boolean;
  credentials: Credentials = new Credentials();
  busy: Subscription;

  email: string;
  redirectUrl: string;
  CIN: string;
  passport: string;
  nom: string;
  content: string;
  topic: string;
  private isCin: boolean = true;

  ngOnInit() {
    // jQuery(".alert").alert('close');
    jQuery(".alert").hide();
  }

  constructor(private stoarageService: StorageService,
              private userService: UserService,
              private conversationService: ConversationService,
              private router: Router,
              private authService: AuthService,
              private route: ActivatedRoute) {


  }

  loginSubmit() {
    this.isLoading = true;
    this.credentials.password = this.credentials.password.split(' ').join('');
    this.route.queryParams
      .subscribe(
        params => {
          this.busy = this.authService.login(this.credentials)
            .subscribe(
              (data) => {
                this.isLoading = false;
                this.stoarageService.write("teacher", data.enseignant);
                this.stoarageService.write("teacher-token", data.token);
                this.userService.loggedUser = data.enseignant;
                console.log(params['redirectUrl']);
                if (!params['redirectUrl'])
                  this.router.navigate(["/teacher-file"], {queryParams: {reload: true}});
                else
                  this.router.navigate([params['redirectUrl']], {queryParams: {reload: true}});
              },
              (error) => {
                console.log(error);
                let message = "Erreur";
                if (error.status === 400) {
                  message = "Email non confirmé";
                }
                this.isLoading = false;
                swal({
                  title: "Erreur!",
                  text: message,
                  type: "error"
                });
              }
            )
        }
      );

  }

  openModalPasswordForgot() {
    jQuery("#modal_form_vertical").modal();
  }

  sendEmail() {
    const baseContext = this;
    this.busy = this.userService.forgotPassword(baseContext.email).subscribe(data => {
      swal({
        title: "Succés!",
        text: 'Mot de passe réinitialisé avec succès , consulter votre mail ',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    }, (error) => {
      console.log(error);
      if (error.status === 404) {
        swal({
          title: "Erreur",
          text: "Vous n'avez pas un compte dans cette platforme ! ",
          type: "error"
        });
      } else {
        swal({
          title: "Erreur",
          text: 'Une erreur est survenu , déclarer une réclamation ou essayer au plus tard ',
          type: "error"
        });
      }
    });
    jQuery("#modal_form_vertical").modal("hide");
  }

  openModalReclamation() {
    jQuery("#modal_form_reclamation").modal();
  }

  envoyerReclamation() {
    const baseContext = this;
    this.busy = this.conversationService.startConversationUnregistered(1,
      baseContext.email, baseContext.nom, null, baseContext.CIN, baseContext.passport,
      baseContext.content, baseContext.topic).subscribe(data => {
      swal({
        title: "Succés!",
        text: 'Réclamation envoyée avec succès, la réponse vous sera communiquée par email',
        confirmButtonColor: "#66BB6A",
        type: "success"
      });
    }, (error) => {
      swal({
        title: "Erreur",
        text: 'Une erreur est survenue ,  essayer plus tard ',
        type: "error"
      });

    });
    jQuery("#modal_form_reclamation").modal("hide");

  }

  setCIN() {
    this.isCin = true;
    this.passport = null;
  }

  setPassport() {
    this.isCin = false;
    this.CIN = null;
  }
}


