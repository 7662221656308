import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {_throw} from 'rxjs/observable/throw';

@Injectable()
export class GenericService {
  headers: HttpHeaders;

  constructor() {
    this.headers = new HttpHeaders({'Content-Type': 'application/json'});
  }

  handleResponse(response: any) {
    console.log('**** response *****');
    console.log('status ' + response.status);
    console.log('statusText ' + response.statusText);
    console.log('url ' + response.url);
    console.log('headers ' + JSON.stringify(response.headers));
    console.log('**** response *****');
  }


  handleErrors(error: HttpErrorResponse) {
    console.log(error);
    console.log('**** Error *****');
    console.log('status ' + error.status);
    console.log('statusText ' + error.statusText);
    console.log('url ' + error.url);
    console.log('headers ' + JSON.stringify(error.headers));
    console.log(JSON.stringify(error.error));
    console.log('**** error *****');
    return _throw(error);
  }

}
