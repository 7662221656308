import {Component, OnInit} from "@angular/core";
import {UserService} from "../../shared/services/user.service";
import {Subscription} from "rxjs/Subscription";
import {Utils} from "../../shared/utils";
import {SectionValidation} from "app/shared/models/section-validation";
import {StorageService} from "../../shared/services/storage.service";
import {TeacherFileService} from "../../shared/services/teacher-file.service";
import {Teacher} from "../../shared/models/Teacher";
import {TeacherConcour} from "../../shared/models/Teacher_Concour";

declare let jQuery: any;
declare let swal: any;

@Component({
  templateUrl: 'dashboard-teacher-file.component.html',
  styleUrls: [],
})
export class DashboardTeacherFileComponent implements OnInit {

  teacher: Teacher;
  progress = 50;
  busy: Subscription;
  globalStatus: string;

  ngOnInit() {
    const baseContext = this;
    this.teacher = this.getLoggedUser();
    // this.userService.loggedUser = this.teacher;
    console.log(this.teacher);

    // Basic example
    this.calculProgress();
    jQuery(".jui-progressbar").progressbar({
      value: baseContext.progress,
      create: function (event, ui) {
        jQuery(this).find('.ui-widget-header').css({'background-color': 'green'})
      }
    });

    console.log(this.teacher);
    this.globalStatus = this.getStatus(
      this.getLoggedUser().dossier_status,
      this.getLoggedUser().administration_review
    );
  }

  constructor(private userService: UserService,
              private teacherFileService: TeacherFileService,
              private stoarageService: StorageService) {

  }

  calculProgress() {

    if (this.teacher.img) {
      this.progress += 50;
    }
  }

  public getLoggedUser() {
    return this.userService.loggedUser;
  }

  submitAdmin() {
    const sectionIds = this.getSectionIds(this.getSections());
    this.busy = this.teacherFileService.submitAdmin(sectionIds)
      .subscribe(
        (data) => {
          this.userService.loggedUser.dossier_status = 1;
          this.globalStatus = this.getStatus(1, this.teacher.administration_review);
          this.userService.loggedUser.validations = data;
          this.stoarageService.write("teacher", this.userService.loggedUser);
          swal({
            title: "Succès!",
            text: 'Dossier envoyé avec succées',
            confirmButtonColor: "#66BB6A",
            type: "success"
          });
        },
        (error) => {

        }
      )
  }

  getSections() {
    const sections: Section[] = [];

    let section: Section;
    let sectionTmp: Section;
    let status: number;
    // General Informations
    section = new Section();
    section.id = 1;
    section.name = "Information générale";
    section.url = "/teacher-file/general/edit";
    status = this.getStatusSection(this.getLoggedUser().first_name, 1,
      this.getLoggedUser().validations);
    sectionTmp = this.settingFromStatus(section, status);
    sections.push(sectionTmp);


    // Bac
    section = new Section();
    section.id = 2;
    section.name = "Baccalauréat";
    section.url = "/teacher-file/bac-info";
    status = this.getStatusSection(this.getLoggedUser().bac, 2,
      this.getLoggedUser().validations);
    sectionTmp = this.settingFromStatus(section, status);
    sections.push(sectionTmp);
    // Photo
    section = new Section();
    section.id = 4;
    section.name = "Photos";
    section.url = "/teacher-file/upload-photo";
    status = this.getStatusSection(this.getLoggedUser().img, 4,
      this.getLoggedUser().validations);
    sectionTmp = this.settingFromStatus(section, status);
    sections.push(sectionTmp);

    // Doctorat
    section = new Section();
    section.id = 6;
    section.name = "Diplôme de docteur en médecine";
    section.url = "/teacher-file/doctaurat";
    status = this.getStatusSection(this.getLoggedUser().doctaurat, 6,
      this.getLoggedUser().validations);
    sectionTmp = this.settingFromStatus(section, status);
    sections.push(sectionTmp);
    // Fonctions
    section = new Section();
    section.id = 5;
    section.name = "Fonctions";
    section.url = "/teacher-file/fonction";
    if (!this.getLoggedUser().fonctions) {
      status = this.getStatusSection(null, 5,
        this.getLoggedUser().validations
      );
    } else {
      status = this.getStatusSection(this.getLoggedUser().fonctions.length === 0 ? null : "", 5,
        this.getLoggedUser().validations);
    }
    sectionTmp = this.settingFromStatus(section, status);
    sections.push(sectionTmp);

    // Résidanat
    section = new Section();
    section.id = 7;
    section.name = "Residanat";
    section.url = "/teacher-file/residanat";
    status = this.getStatusSection(this.getConcourByType(1, this.getLoggedUser().concours), 7,
      this.getLoggedUser().validations);
    sectionTmp = this.settingFromStatus(section, status);
    sections.push(sectionTmp);

    // Assistanat
    section = new Section();
    section.id = 8;
    section.name = "Assistanat";
    section.url = "/teacher-file/assistanat";
    status = this.getStatusSection(this.getConcourByType(2, this.getLoggedUser().concours), 9,
      this.getLoggedUser().validations);
    sectionTmp = this.settingFromStatus(section, status);
    sections.push(sectionTmp);
    // Aggregation
    section = new Section();
    section.id = 9;
    section.name = "Aggregation";
    section.url = "/teacher-file/aggregation";
    status = this.getStatusSection(this.getConcourByType(3, this.getLoggedUser().concours), 9,
      this.getLoggedUser().validations);
    sectionTmp = this.settingFromStatus(section, status);
    sections.push(sectionTmp);
    // Aggregation
    section = new Section();
    section.id = 10;
    section.name = "Professorat";
    section.url = "/teacher-file/professorat";
    status = this.getStatusSection(this.getConcourByType(4, this.getLoggedUser().concours), 9,
      this.getLoggedUser().validations);
    sectionTmp = this.settingFromStatus(section, status);
    sections.push(sectionTmp);


    return sections;
  }

  public getConcourByType(concourType: number, concours: TeacherConcour[]): TeacherConcour {
    if (!concours || concours.length === 0) {
      return null;
    }
    for (let i = 0; i < concours.length; i++) {
      if (concours[i].id_Concour_Type === concourType) {
        return concours[i];
      }
    }
    return null;
  }

  private settingFromStatus(section: Section, status: number) {

    switch (status) {
      case 1 : {
        section.imgIcon = "icon-plus3";
        section.imgStyling = "border-brown-600 text-brown-600";
        section.progressLabel = "Vide";
        section.btnLabel = "Ajouter";
        section.btnIcon = "icon-plus2";
        break
      }
      case 2 : {
        section.imgIcon = "icon-file-download2";
        section.imgStyling = "border-primary text-primary";
        section.progressLabel = "Enregistré";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
      case 3 : {
        section.imgIcon = "icon-spinner9";
        section.imgStyling = "border-primary text-primary";
        section.progressLabel = "Envoyé";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
      case 4 : {
        section.imgIcon = "icon-hour-glass";
        section.imgStyling = "border-primary text-primary";
        section.progressLabel = "En cour de taitement";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
      case 5 : {
        section.imgIcon = "icon-checkmark";
        section.imgStyling = "border-success text-success";
        section.progressLabel = "Validé";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
      case 6 : {
        section.imgIcon = "icon-warning22";
        section.imgStyling = "border-warning-300 text-warning-300";
        section.progressLabel = "A revisé";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
      case 7 : {
        section.imgIcon = "icon-cross";
        section.imgStyling = "border-danger text-danger";
        section.progressLabel = "Refusé";
        section.btnLabel = "Modifier";
        section.btnIcon = "icon-pen2";
        break
      }
    }
    section.status = status;
    return section;

  }


  public getStatus(dossier_status: number, valid_from_administration: number) {

    /* console.log("Get Status");
     console.log(dossier_status, valid_from_administration);*/
    if (dossier_status === 0) {
      return "Pas encore envoyé"; // Stored
    } else if (dossier_status === 1) {
      return "Envoyé"; // Submit
    } else if (dossier_status === 2) {
      return "Reverifié"; // In review
    } else {
      if (valid_from_administration === 1) {
        return "Validé"; // Accepted
      } else if (valid_from_administration === 2) {
        return "Reverifié"; // Reviewed
      } else {
        return "Refusé"; // Refused
      }
    }

  }

  private getStatusSection(object: any, sectionId: number,
                           validations: SectionValidation[]) {

    if (object == null) {
      return 1; // Empty
    } else {
      const statusSection = Utils.getStatusSection(validations, sectionId);
      if (statusSection === null) {
        return 2; // Enregistré
      } else {
        if (statusSection.status === 0) {
          return 3; // Envoyé
        } else if (statusSection.status === 1) {
          return 5; // Valid
        } else if (statusSection.status === 2) {
          return 6; // Reviewed
        } else {
          return 7; // Refused
        }
      }
    }
  }

  private getSectionIds(sections: Section[]) {
    const sectionIds: number[] = [];

    sections.forEach(section => {
      if (section.status === 2 || section.status === 3 || section.status === 6) {
        sectionIds.push(section.id);
      }
    });

    return sectionIds;
  }
}

export class Section {
  public id: number;
  public url: string;
  public imgStyling: string;
  public imgIcon: string;
  public progressLabel: string;
  public name: string;
  public btnLabel: string;
  public btnIcon: string;
  public status: number;

}


