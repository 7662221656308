import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListDemandPostTrainingComponent} from './list-demand-post-training/list-demand-post-training.component';
import {SharedModule} from "../shared/shared.module";
import {AttributionsRoutingModule} from "./attributions.routing";
import {ListValidatedInscriptionsComponent} from "./list-validated-inscriptions/list-validated-inscriptions.component";
import {ManageFormationsComponent} from './manage-formations/manage-formations.component';

@NgModule({
  imports: [
    AttributionsRoutingModule,
    CommonModule,
    SharedModule
  ],
  declarations: [
    ListValidatedInscriptionsComponent,
    ListDemandPostTrainingComponent,
    ManageFormationsComponent
  ]
})
export class AttributionsModule {
}
