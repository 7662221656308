import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Utils} from "../../utils";

declare var jQuery: any;

@Component({
  selector: 'app-ng-datepicker',
  templateUrl: './ng-datepicker.component.html',
  styleUrls: ['./ng-datepicker.component.css']
})
export class NgDatepickerComponent implements OnInit, AfterViewInit {

  className: string;

  @Input()
  withTime: boolean;


  @Output()
  selectionChange: EventEmitter<any>;

  selected: any;

  @Input()
  get selection() {
    return this.selected;
  }

  set selection(value) {
    const baseContext = this;
    if (value) {
      setTimeout(function () {
        jQuery("." + baseContext.className).data('daterangepicker').setStartDate(Utils.convertDate(value));
        jQuery("." + baseContext.className).data('daterangepicker').setEndDate(Utils.convertDate(value));
      }, 20);
    }
    baseContext.selected = value;
  }

  constructor() {
    this.selectionChange = new EventEmitter<any>();
    this.className = Utils.getRandomString();
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    const baseContext = this;
    Utils.initDatePicker(baseContext.className, baseContext.withTime);
    jQuery("." + baseContext.className).on('change', function () {
      console.log(jQuery(this).val());
      baseContext.selected = Utils.convertDateServer(jQuery(this).val());
      baseContext.selectionChange.emit(baseContext.selected);
    });
  }

}
