import {Component, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {UserService} from "../../shared/services/user.service";
import {PartielNotificationService} from "../../shared/services/partielNotification.service";
import {Teacher} from "../../shared/models/Teacher";
import {environment} from "../../../environments/environment";


@Component({
  templateUrl: 'menu.component.html',
  styleUrls: [],

})
export class MenuComponent implements OnInit {

  loggedUser: Teacher = new Teacher();

  baseURL: string;
  busy: Subscription;

  year_university_notification_number: number;


  ngOnInit() {
    this.loggedUser = this.userService.loggedUser;
  }

  constructor(public userService: UserService, private  partielNotificationService: PartielNotificationService) {
    this.baseURL = environment.baseUrl;
  }
}
