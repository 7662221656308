import {SessionStage} from "./SessionStage";
import {PeriodeStage} from "./PeriodeStage";
import {Evaluation} from "./Evaluation";

export class Periode {
  public periode_id: number;
  public start_date: string; // X S S S
  public end_date: string; // S S S X

  public end_middle_date?: string; // S X S S
  public start_middle_date?: string; // S S X S

  public isRupture: boolean = false;
  public niveau_id: number;

  public session_stage_id: number;

  public session_stage: SessionStage = new SessionStage();
  public label: string = "Periode";
  public no_speriodes: number = 1;
  public periodes_stages: PeriodeStage[] = [];
  public evaluations: Evaluation[] = [];
}
