import {S_Periode} from "./S_Periode";
import {Periode} from "./Periode";

export class PeriodeStage {
  public periode_stage_id: number;
  public week_number: number;
  public session: number;
  public periode_id: number;

  public speriodes: S_Periode[] = [];
  public periode: Periode = new Periode();
}
