import {Injectable} from "@angular/core";
import {GenericService} from "./generic.service";
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {StorageService} from "./storage.service";
import {Reclamation} from "../models/reclamation";
import {environment} from "../../../environments/environment";

/**
 * Created by AHMED on 04/08/2017.
 */
@Injectable()
export class ReclamationService extends GenericService {

  constructor(private http: HttpClient, private stoarageService: StorageService) {
    super();
  }


  addReclamation(reclamation: Reclamation) {
    const headers = this.headers.set("Authorization", "Bearer " + this.stoarageService.read("student-token"));
    const url = environment.baseUrl + "/reclamation/add";

    return this.http.post<any>(url, JSON.stringify(reclamation), {
      headers: headers
    })
      .pipe(catchError(this.handleErrors));
  }


}
