/**
 * Created by Abbes on 14/09/2017.
 */
/**
 * Created by Abbes on 21/08/2017.
 */
/**
 * Created by Abbes on 21/08/2017.
 */
import {Component, Input, OnInit} from "@angular/core";
declare var jQuery: any;
declare var swal: any;
@Component({
  selector: 'descriptif-document',
  templateUrl: 'descriptif-document.component.html',
  styleUrls: [],
})
export class DescriptifDocumentComponent implements OnInit {


  ngOnInit() {
  }

}


